<div class="wrapper">
  <div class="content-containter" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div class="content">
      <h1 id="page-header">Facilities</h1>
      <h2 class="paragraph-header">Safe and Functional Facility Designs</h2>
      <p>
        Industry experience, diligence, and creativity are required to prepare a
        design that ensures the safety and operational success of a facility.
        Our team works closely with owners and developers to meet performance
        goals and cost objectives.
      </p>
      <p>
        TtR has extensive petroleum liquids facility experience. This includes
        design of origination and booster pump stations, custody transfer
        facilities; LACTs, proving (spherical ball & compact small volume), and
        terminal facilities, above ground storage tanks (floating roof),
        spherical tanks (NGL, propane, butane, octanes), truck & rail
        loading/unloading, firewater systems & ponds, facility relief systems,
        and drain & sump systems. TtR’s pump experience includes a variety of
        API 610 pumps; axial split, single & multi-stage pumps, vertical can;
        with and w/o lube oil systems, positive displacement, HPS , sump, and
        firewater.
      </p>
      <p>
        Additionally, TtR’s petroleum gas facility experience includes inlet
        handling facilities, gathering systems, gas compression (both gas and
        electric driven), vessel design, metering & regulation runs and skids,
        CO2 & amine treatment, glycol & mole sieve dehydration, refrigeration,
        cryogenic natural gas liquids (NGL) recovery, storage & truck loading,
        LPG terminals, waste water & condensate handling, relief systems &
        flares, and drain systems along with all other required utility systems.
      </p>
      <p>
        Whether our clients are upgrading a brownfield facility or designing a
        new asset from scratch, TtR has the expertise to provide complete
        solutions.
      </p>
      <h2 class="paragraph-header">Crude Pipeline Reversal</h2>
      <p>
        TtR is preparing facility designs to accommodate a flow reversal on a
        major U.S. pipeline This scope includes conversion of the origination
        station and terminus, updating two midpoint booster stations, and
        modification of existing systems as needed to accommodate the reversal
        of the 40” pipeline.
      </p>

      <div class="image-list-container" fxLayout="row" fxLayout.lt-md="column">
        <div class="image-container" fxFlex.gt-md="1 1 80%">
          <img src="../../../../assets/img/crude-pipeline-reversal.webp" />
        </div>
        <div class="list-container" fxFlex.gt-md="1 1 20%">
          <h2 class="list-header">Key features:</h2>
          <ul>
            <li>22,000 new HP</li>
            <li>Electrical upgrades, new piping and tank manifolds</li>
            <li>New traps, metering, and sampling system</li>
            <li>New substation</li>
          </ul>
        </div>
      </div>
      <h2 class="paragraph-header">Multi-Phase Crude System Expansion</h2>
      <p>
        Over a ten-year period, TtR provided conceptual, feasibility, detailed
        design, and construction management services on this project which
        dramatically increased crude oil transportation and storage capacity.
      </p>

      <div class="image-list-container" fxLayout="row" fxLayout.lt-md="column">
        <div class="image-container" fxFlex.gt-md="1 1 80%">
          <img
            src="../../../../assets/img/milti-phase-crude-expansion.webp"
            alt="multi-phase-crude-oil-expansion"
          />
        </div>
        <div class="list-container" fxFlex.gt-md="1 1 20%">
          <h2 class="list-header">Key Features:</h2>
          <ul>
            <li>Expanded transportation capacity from 85 to 655 MBPD</li>
            <li>Rail loading facilities capable of 80 MBPD</li>
            <li>Pump Stations: 150,000 HP</li>
            <li>Terminals: 2,000,000 BBL crude storage</li>
            <li>New pipeline at 225 MBPD</li>
          </ul>
        </div>
      </div>

      <h2 class="paragraph-header">Regional Program Support</h2>
      <p>
        TtR has been providing regional engineering support for a major
        midstream client throughout the last five years for operational and
        maintenance projects. Design elements have included feasibility, program
        planning, detailed design, construction, and commissioning support.
      </p>

      <div class="image-list-container" fxLayout="row" fxLayout.lt-md="column">
        <div class="image-container" fxFlex.gt-md="1 1 80%">
          <img
            src="../../../../assets/img/regional-program-support.webp"
            alt="3D model overlayed on actual picture"
          />
        </div>
        <div class="list-container" fxFlex.gt-md="1 1 20%">
          <h2 class="list-header">Key Features:</h2>
          <ul>
            <li>Dedicated and consistent team of engineers and designers</li>
            <li>Steady state and transient hydraulic analysis</li>
            <li>Pump reliability, efficiency, and capacity increases</li>
            <li>Pump control options (PCV, VFD, Sync bypass)</li>
            <li>Electrical upgrade programs</li>
            <li>Arc flash mitigation</li>
            <li>Above grade piping programs</li>
            <li>3D facility scanning and as-built documentation</li>
            <li>PHA reviews</li>
          </ul>
        </div>
      </div>

      <h2 class="paragraph-header">NGL Pump and Injection Facilities</h2>
      <p>
        TtR completed the detailed, multi-discipline design of 12 facilities,
        including 6 pump stations, and associated substations for a major
        interstate NGL pipeline project.
      </p>

      <div class="image-list-container" fxLayout="row" fxLayout.lt-md="column">
        <div class="image-container" fxFlex.gt-md="1 1 80%">
          <img
            src="../../../../assets/img/facility-engineering-SM.webp"
            alt="NGL pump and injection facilities"
          />
        </div>
        <div class="list-container" fxFlex.gt-md="1 1 20%">
          <h2 class="list-header">Key Features:</h2>
          <ul>
            <li>
              Two injection facilities with 6 LPG storage spheres with a total
              capacity of 285,000 bbl
            </li>
            <li>46,000 HP in mainline, booster, and injection pumps</li>
            <li>
              Pipeline hydraulic modeling and surge analysis for pump selection
            </li>
            <li>
              Complete, multi-discipline facility design including mechanical,
              structural, civil, electrical, and process engineering
            </li>
            <li>Procurement and construction support</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
