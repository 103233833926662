import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-engineering-mapping',
  templateUrl: './engineering-mapping.component.html',
  styleUrls: ['./engineering-mapping.component.css'],
})
export class EngineeringMappingComponent implements OnInit {
  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('Mapping - Tetra Tech Rooney');
  }
}
