import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about-history',
  templateUrl: './about-history.component.html',
  styleUrls: ['./about-history.component.css'],
})
export class AboutHistoryComponent implements OnInit {
  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('History - Tetra Tech Rooney');
  }
}
