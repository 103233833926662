<div class="wrapper">
  <div class="content-containter" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div class="content" fxFlex>
      <h1 id="page-header">Asset Integrity</h1>
      <p>
        TtR provides customized integrity services to help our clients improve
        the condition of their assets. Our project history includes onshore and
        offshore transmission and gathering lines supporting a diverse range of
        applications throughout the asset lifecycle.
      </p>
      <ul>
        <li>Compliance</li>
        <li>Consequence Identification</li>
        <li>Assessment</li>
        <li>Monitoring</li>
        <li>Mitigation</li>
      </ul>

      <p>
        From Integrity Management Plan development to construction management,
        TtR is your partner to address your integrity challenges.
      </p>
      <h2 class="paragraph-header">
        TFI Tool Inspection and Pipeline Repair Program
      </h2>
      <p>
        TtR managed the inline inspection, rehabilitation, and provided
        construction support on this 932 mile, 20" crude line with longitudinal
        weld seam anomalies.
      </p>

      <div class="image-list-container" fxLayout="row" fxLayout.lt-md="column">
        <div class="image-container" fxFlex.gt-md="1 1 80%">
          <img src="../../../../assets/img/pigging-tool.webp" />
        </div>
        <div class="list-container" fxFlex.gt-md="1 1 20%">
          <h2 class="list-header">Key Features:</h2>
          <ul>
            <li>
              Eight month program to clean and inspect line with tools and TFI
            </li>
            <li>
              Thirteen month rehabilitation program with over 2,300 anomalies
            </li>
            <li>Over 1,700 excavation sites</li>
            <li>600 cut out repairs</li>
            <li>
              250 additional repair/modifications also completed. Span
              replacements, BV install, and lowerings
            </li>
          </ul>
        </div>
      </div>

      <h2 class="paragraph-header">
        Full Service Integrity and Engineering Solutions
      </h2>
      <p>
        Over the last two years, TtR has been working with a midstream operator
        to improve asset integrity and increase flowrate on their gathering
        system.
      </p>

      <div class="image-list-container" fxLayout="row" fxLayout.lt-md="column">
        <div class="list-container" fxFlex.gt-md="1 1 20%">
          <h2 class="list-header">Key Features:</h2>
          <ul>
            <li>Review and update of the Integrity Management Plan</li>
            <li>Oversight of smart pig runs</li>
            <li>Designed corresponding repairs</li>
            <li>Prepared hydrotest plans</li>
            <li>Designed CTB LACT sites to increase oil takeaway</li>
            <li>Improved leak detection measures</li>
          </ul>
        </div>
        <div class="image-container" fxFlex.gt-md="1 1 80%">
          <img src="../../../../assets/img/asset-integrity-webmap.webp" />
        </div>
      </div>
    </div>
    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
