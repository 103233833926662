<div class="wrapper">
  <div class="content-containter" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div class="content" fxFlex>
      <h1 id="page-header">Pipelines</h1>
      <h2 class="paragraph-header">
        Intelligent Designs To Minimize Cost and Environmental Disturbance
      </h2>
      <p>
        TtR provides high quality, engineering-based pipeline design along with
        permitting, survey, right-of-way, and land acquisition support for
        linear pipeline projects across the nation.
      </p>
      <p>
        These projects include both intrastate and interstate projects with
        thousands of miles of installed pipeline along varying terrain,
        wetlands, and elevation changes with multiple foreign pipeline, water,
        utility, railroad, and road & highway crossings. TtR evaluates many
        aspects of pipeline routing by effectively choosing a route which is the
        shortest, easiest to construct, meets project schedule, avoids
        environmentally sensitive areas and major permitting issues.
      </p>
      <p>
        Whether our clients are replacing a few segments of an aging line or
        designing a new system, TtR has the expertise to provide complete
        solutions to pipeline challenges.
      </p>
      <h2 class="paragraph-header">Interstate NGL pipelines</h2>
      <p>
        TtR provided engineering design, comprehensive federal/state/local
        environmental and cultural resources survey and permitting, and
        construction environmental compliance services for two parallel NGL
        pipelines.
      </p>

      <div class="image-list-container" fxLayout="row" fxLayout.lt-md="column">
        <div class="image-container" fxFlex.gt-md="1 1 80%">
          <img
            src="../../../../assets/img/interstate-ngl-pipelines.webp"
            alt="pipeline right of way through farm land"
          />
        </div>
        <div class="list-container" fxFlex.gt-md="1 1 20%">
          <h2 class="list-header">Key features:</h2>
          <ul>
            <li>360 miles of 20” and 260 miles of parallel 16” NGL pipeline</li>
            <li>Design capacity of 280 MBPD</li>
            <li>64 block valves and associated substations</li>
            <li>
              271 horizontally drilled crossings totaling 113 miles in length
            </li>
            <li>341 bored crossings</li>
            <li>Management of project WebMap for pipeline documentation</li>
            <li>
              Multi-discipline engineering, environmental and cultural survey,
              permitting, procurement and construction support, and
              environmental compliance
            </li>
          </ul>
        </div>
      </div>

      <h2 class="paragraph-header">Texas Crude Pipeline</h2>
      <p>
        TtR provided engineering design, construction support, and comprehensive
        environmental survey, and permitting for this 338 mile pipeline project
        in Texas.
      </p>

      <div class="image-list-container" fxLayout="row" fxLayout.lt-md="column">
        <div class="list-container" fxFlex.gt-md="1 1 20%">
          <h2 class="list-header">Key features:</h2>
          <ul>
            <li>Design capacity of 350 MBPD with 24" and 20" pipeline</li>
            <li>Three above ground storage terminals</li>
            <li>
              30 horizontally drilled crossings totaling 7 miles in length
            </li>
            <li>Midpoint booster station</li>
            <li>Major piping redesign at terminal</li>
            <li>
              Multi-discipline engineering for pipeline and facility design,
              environmental survey, permitting, procurement and construction
              support
            </li>
          </ul>
        </div>
        <div class="image-container" fxFlex.gt-md="1 1 80%">
          <img
            src="../../../../assets/img/texas-crude-pipeline.webp"
            alt="pipeline next to trench"
          />
        </div>
      </div>

      <h2 class="paragraph-header">Interstate NGL Pipeline</h2>
      <p>
        TtR provided engineering design, construction support, and coordination
        of ROW, survey, and permitting on the northern 212-mile section of a 900
        mile NGL pipeline in the Rockies.
      </p>

      <div class="image-list-container" fxLayout="row" fxLayout.lt-md="column">
        <div class="image-container" fxFlex.gt-md="1 1 80%">
          <img
            src="../../../../assets/img/interstate-ngl-traps.webp"
            alt="pigging traps"
          />
        </div>
        <div class="list-container" fxFlex.gt-md="1 1 20%">
          <h2 class="list-header">Key features:</h2>
          <ul>
            <li>Design capacity of 400 MBPD with 20” pipeline</li>
            <li>
              Designed 38 directional drills, the longest of which was 6,100 ft
            </li>
            <li>
              Crossings included:
              <ul>
                <li>197 wetlands and waterbodies</li>
                <li>2 railroads</li>
                <li>1 interstate</li>
                <li>7 state highways</li>
                <li>43 county roads</li>
              </ul>
            </li>
            <li>Originating metering facility</li>
            <li>3 trap sites</li>
            <li>Manifolds for future stations</li>
            <li>30 mainline valve installations</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
