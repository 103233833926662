<div class="wrapper">
  <div class="content-containter" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div class="content">
      <h1 id="page-header">Software Based Tools and Automation Solutions</h1>
      <h2 class="paragraph-header">
        Automation - Reduce Man Hours and Errors - Standardize Data Storage
      </h2>
      <p>
        Much of the important work individuals are performing is slowed by
        highly repetitive semi-standardized tasks such as data entry, standard
        document generation, form completion and data transfer activities. Human
        labor is a sub-optimal, error-prone, and expensive solution to this
        problem, however, custom software tools can completely eliminate the
        need for human involvement in many of these tasks and can optimize
        workflow, saving countless hours of repetitive labor, allowing your team
        to focus on the important tasks at hand.
      </p>
      <p>
        Custom software solutions can automate onerous tasks while greatly
        reducing the time required for completion and eliminating human errors.
        Programmatic data collection ensures that every critical event is
        recorded with all of the related data points desired at no extra time
        cost. Nearly any repetitive and standard process involving
        computer-based product can be highly optimized programmatically, saving
        valuable resources.
      </p>
      <p>
        At Tetra Tech Rooney we have experience with a broad range of
        programming languages and scripting tools to create the best-fit
        application to suit your needs. Our team of programmers are
        professionals with a diverse background who understand the need for
        simplicity and out-of-the-box functionality.
      </p>
      <div class="image-container">
        <img src="../../../../../assets/img/ai-hand.webp" />
      </div>
      <h2 class="paragraph-header">Process Automation Capabilities</h2>
      <ul>
        <li>Data Entry</li>
        <li>Data Collection</li>
        <li>Document Generation</li>
        <li>Form Completion</li>
        <li>Communication Automation</li>
        <li>Rule Based Automatic Decision Making</li>
        <li>Machine Learning</li>
      </ul>
      <div fxLayout="row" fxLayout.lt-md="column">
        <div fxFlex.gt-md="1 1 33%">
          <h2 class="list-header">Programming Languages</h2>
          <ul>
            <li>C#</li>
            <li>C++</li>
            <li>Visual Basic</li>
            <li>JavaScript</li>
            <li>TypeScript</li>
            <li>Python</li>
            <li>HTML</li>
            <li>CSS</li>
            <li>XML</li>
            <li>XAML</li>
          </ul>
        </div>
        <div fxFlex.gt-md="1 1 33%">
          <h2 class="list-header">Technology</h2>
          <ul>
            <li>.Net</li>
            <li>Angular</li>
            <li>Express</li>
            <li>Scikit-Learn</li>
            <li>Tensorflow</li>
            <li>MS Office Add-ins</li>
            <li>VBA Scripting</li>
            <li>WinForms</li>
            <li>WPF</li>
            <li>Node.js</li>
            <li>Mongo DB</li>
            <li>SQL</li>
            <li>SQLite</li>
            <li>Access</li>
          </ul>
        </div>
        <div fxFlex.gt-md="1 1 33%">
          <h2 class="list-header">Solutions</h2>
          <ul>
            <li>Web Access Database Front Ends</li>
            <li>Internal Network Database Front Ends</li>
            <li>Database Dashboards</li>
            <li>Compiled Software Tools</li>
            <li>Command Line or GUI Interfaces</li>
            <li>MS Office Based Solutions</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
