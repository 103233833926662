<div class="wrapper">
  <div class="content-containter" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div class="content" fxLayout="column">
      <h1 id="page-header">Civil/Structural Engineering</h1>

      <div class="text-container">
        <h2 class="paragraph-header">Civil Engineering</h2>
        <p>
          TtR staff has designed the necessary civil requirements for both
          facilities and pipeline systems including site grading and surfacing,
          access road & ramp development, ground cover over utilities, tank &
          equipment containment, water drainage, and firewater ponds. Our
          evaluations consider the various types of equipment and vehicles that
          will be utilized on-site during construction and normal operations.
          Subsequently, our designs will incorporate adequate truck turn around
          space and ensure proper load distribution for below-grade piping and
          utilities. This is especially important on brownfield sites where
          construction equipment may travel over existing operating piping.
        </p>
        <p>
          Our design expertise includes the development of full engineering
          packages including demolition and grading drawings, grading profile
          drawings, road plan & profile drawings, road section details, fencing
          & surfacing details, gate & signage details, drainage & erosion
          control details, and culvert details.
        </p>
      </div>
      <div class="image-container">
        <img
          src="../../../../../assets/img/civil-structural-tank-site-grading.webp"
          alt="3d model of tanks and grading"
        />
      </div>

      <div class="text-container">
        <h2 class="paragraph-header">Structural Engineering</h2>

        <p>
          TtR staff has experience in designing the necessary structural
          requirements for both facilities and pipeline systems including pump &
          building foundations, above ground tank foundations, supports (valves,
          pipe, & cable tray), auxiliary instrumentation, access stairs and
          platforms , and bollard & jersey barriers. This includes coordinating
          with vendor supplied equipment skids to properly designs supports and
          foundations and locating mounting/anchor points.
        </p>
        <p>
          Our design expertise includes the development of full engineering
          packages including foundation plan drawings, anchor bolt schedule &
          details, foundation location plans, support foundation schedule &
          details (valves, pipe, & cable tray), foundation details, steel
          framing & connection details, grouting details, stair and platform
          details, typical structural details for instrumentation.
        </p>
      </div>
      <div class="image-container">
        <img src="../../../../../assets/img/structural.webp" />
      </div>
      <div class="spacer"></div>
    </div>
    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
