<div class="wrapper">
  <div class="content-containter" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div class="content">
      <h1 id="page-header">Project Feasibility</h1>
      <p>
        Tetra Tech Rooney (TtR) has completed several Project Feasibility
        studies for our clients. We consider and evaluate all aspects of the
        project including cost, schedule, operational and technical
        requirements, permitting, environmental impact, constructability, and
        return on investment. Potential issues are identified, options and
        alternatives are explored, and economic comparisons are provided.
        Finally, upon completion of the analysis, TtR provides a recommendation
        of path forward. This approach to project feasibility analysis can be
        applied to small project components or large full-scale turnkey
        projects.
      </p>
      <div class="image-container">
        <img src="../../../../../assets/img/project-feasability-LG.webp" />
      </div>
    </div>
    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
