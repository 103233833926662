<div class="wrapper">
  <div class="header-image"></div>
  <div class="content-container" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div class="content-block" fxLayout="column">
      <div class="text-header" fxFlex>
        <h1 id="page-header">Solutions</h1>
        <p>
          We proudly offer solutions to meet our client's every need. Tetra Tech
          Rooney offers an ever-expanding knowledge base that allows us to keep
          up with industry standards and technologies while maintaining our
          efficiency and integrity so that the products we provide are always of
          the highest caliber.
        </p>
      </div>
      <h2 class="image-header paragraph-header">Engineering</h2>
      <div
        class="image-link-block"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxFlex
      >
        <div class="image-container" fxFlex.gt-md="1 1 40%">
          <div class="image" id="image1"></div>
        </div>
        <div
          class="link-column"
          fxFlex.gt-md="1 1 20%"
          fxLayout="column"
          fxLayoutAlign="space-between"
          fxLayoutGap.lt-md="5px"
        >
          <a class="link" routerLink="/solutions/engineering/arc-flash"
            >Arc-Flash Studies and Power System Analysis</a
          >
          <a class="link" routerLink="/solutions/engineering/civil-structural"
            >Civil/Structural Engineering</a
          >
          <a class="link" routerLink="/solutions/engineering/commercial"
            >Commercial and Due Diligence</a
          >
          <a class="link" routerLink="/solutions/engineering/drawings"
            >Detailed Design Drawings</a
          >
          <a class="link" routerLink="/solutions/engineering/electrical"
            >Electrical Engineering</a
          >
          <a class="link" routerLink="/solutions/engineering/facility"
            >Facility Engineering</a
          >
        </div>
        <div
          class="link-column"
          fxFlex.gt-md="1 1 20%"
          fxLayout="column"
          fxLayoutAlign="space-between"
          fxLayoutGap.lt-md="5px"
        >
          <a class="link" routerLink="/solutions/engineering/hydraulics"
            >Hydraulics and Pipe Stress Analysis</a
          >
          <a class="link" routerLink="/solutions/engineering/mapping"
            >Mapping</a
          >
          <a class="link" routerLink="/solutions/engineering/permitting"
            >Permitting Support</a
          >
          <a class="link" routerLink="/solutions/engineering/pipeline"
            >Pipeline Engineering</a
          >
          <a class="link" routerLink="/solutions/engineering/feasability"
            >Project Feasibility</a
          >
          <a class="link" routerLink="/solutions/engineering/trenchless"
            >Trenchless Crossings</a
          >
        </div>
      </div>
      <h2 class="image-header paragraph-header">GIS & Digital Technologies</h2>
      <div
        class="image-link-block"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxFlex
      >
        <div class="image-container" fxFlex.gt-md="1 1 40%">
          <div class="image" id="image2"></div>
        </div>
        <div
          class="link-column"
          fxFlex.gt-md="1 1 20%"
          fxLayout="column"
          fxLayoutAlign="space-between"
          fxLayoutGap.lt-md="5px"
        >
          <a class="link" routerLink="/solutions/tech/laser-scanning"
            >Digital Twin Facility and 3D Laser Scanning</a
          >
          <a class="link" routerLink="/solutions/tech/geographic-information"
            >Geographic Information Systems</a
          >
          <a class="link" routerLink="/solutions/tech/gis-dashboard"
            >GIS Digital Dashboard</a
          >
          <a class="link" routerLink="/solutions/tech/automation"
            >Software Based Tools and Automation</a
          >
        </div>
      </div>
      <h2 class="image-header paragraph-header">Asset Integrity</h2>
      <div
        class="image-link-block"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxFlex
      >
        <div class="image-container" fxFlex.gt-md="1 1 40%">
          <div class="image" id="image3"></div>
        </div>
        <div
          class="link-column"
          fxFlex.gt-md="1 1 20%"
          fxLayout="column"
          fxLayoutAlign="space-around"
          fxLayoutGap.lt-md="5px"
        >
          <a class="link" routerLink="/solutions/integrity/permitting"
            >Permitting Support</a
          >
          <a class="link" routerLink="/solutions/integrity/pipeline"
            >Pipeline Integrity and Rehabilitation</a
          >
        </div>
      </div>
      <h2 class="image-header paragraph-header">Project Management</h2>
      <div
        class="image-link-block"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxFlex
      >
        <div class="image-container" fxFlex.gt-md="1 1 40%">
          <div class="image" id="image4"></div>
        </div>
        <div
          class="link-column"
          fxFlex.gt-md="1 1 20%"
          fxLayout="column"
          fxLayoutAlign="space-around"
          fxLayoutGap.lt-md="5px"
        >
          <a
            class="link"
            routerLink="/solutions/project-management/construction"
            >Project Management and Construction Support</a
          >
          <a
            class="link"
            routerLink="/solutions/project-management/supply-chain"
            >Procurement and Supply Chain</a
          >
          <a class="link" routerLink="/solutions/project-management/scheduling"
            >Project Controls and Scheduling</a
          >
        </div>
      </div>
    </div>
    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
