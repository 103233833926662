<div class="wrapper">
  <div class="content-containter" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div class="content">
      <h1 id="page-header">Project Controls and Scheduling</h1>
      <div class="image-container" id="image-1"></div>
      <h2 class="paragraph-header">
        Project support to maintain budget and schedule
      </h2>
      <p>
        From the moment a project is initiated through closeout, it is necessary
        to gather, manage, and analyze data so that decisions can be made to
        reduce risk. This is accomplished by ensuring that deliverables are
        successfully completed, budget preserved, and schedule maintained. In
        addition, the data supports governance objectives to ensure that project
        costs are managed in accordance with business practices.
      </p>
      <p>
        Project controls are a specialized set of skills using tools, metrics,
        and reports that are critical to achieving project deliverables in a
        favorable outcome. At TtR, we take ownership of our role as project
        controllers and understand that we can create value on the project by
        reducing risk and eliminating waste. Utilizing premier software tools
        such as Primavera and Microsoft Project, we analyze the overall project
        health, provide solutions for unplanned events, and correct the course
        when a project deviates from the plan. Some of our functions include:
      </p>
      <ul>
        <li>Analyze efficiency of work teams on deliverables</li>
        <li>Improve estimating tools</li>
        <li>Capture and assess additions to scope</li>
        <li>Construction coordination and cost control</li>
        <li>Forecast costs of completing the project</li>
        <li>Project cost accrual entry</li>
      </ul>
    </div>
    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
