import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-tech-gis-dashboard',
  templateUrl: './tech-gis-dashboard.component.html',
  styleUrls: ['./tech-gis-dashboard.component.css'],
})
export class TechGisDashboardComponent implements OnInit {
  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('GIS Dashboard - Tetra Tech Rooney');
  }
}
