import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-engineering-electrical',
  templateUrl: './engineering-electrical.component.html',
  styleUrls: ['./engineering-electrical.component.css'],
})
export class EngineeringElectricalComponent implements OnInit {
  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('Electrical Engineering - Tetra Tech Rooney');
  }
}
