<div class="wrapper">
  <div class="content-containter" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div class="content">
      <h1 id="page-header">Project Management and Construction Support</h1>
      <div class="image-container" id="image-1"></div>
      <h2 class="paragraph-header">Project Management</h2>
      <p>
        Tetra Tech Rooney specializes in complete project management, from
        proposal to project close-out. Our experienced project managers focus on
        the four main objectives of the project (Scope, Schedule, Budget, and
        Quality) in order to ensure a successful execution and client
        satisfaction. Throughout the course of the project, our project manager
        will create a baseline schedule and lead our multi-disciplined
        engineering teams for timely delivery of objectives. Change management,
        cash management, and project forecasting are all critical to project
        success, and Tetra Tech Rooney’s project managers are mentored and
        trained in these key areas, allowing our clients to have confidence in
        our delivery process.
      </p>
      <p>
        Tetra Tech Rooney has been the engineering company of record and
        provided engineering support for facilities and pipelines. During the
        multiple phases of the projects TtR has reviewed the contractor(s) data
        to ensure it was it was within an acceptable engineering tolerances.
      </p>
      <p>
        On recent projects, TtR has created Request For Information (RFI)
        documentation that was a fast and efficient communication between the
        contractor, client and TtR. In addition to the RFI, TtR has utilized a
        Management Of Change (MOC) procedure to assist with documentation of
        client approved changes for all phases of the project.
      </p>
      <h2 class="paragraph-header">Construction Administration</h2>
      <p>
        We can perform a wide range of construction administrative services to
        include progress reporting, material requisition, inspector reports, and
        any additional services to suit our client's needs.
      </p>
    </div>
    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
