import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-engineering-commercial',
  templateUrl: './engineering-commercial.component.html',
  styleUrls: ['./engineering-commercial.component.css'],
})
export class EngineeringCommercialComponent implements OnInit {
  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('Commercial Due Diligence - Tetra Tech Rooney');
  }
}
