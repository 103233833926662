<div class="wrapper">
  <div class="content-containter" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div class="content">
      <h1 id="page-header">Gathering Systems</h1>
      <h2 class="paragraph-header">
        Building The Networks To Get Petroleum To Market
      </h2>
      <p>
        Combining our expertise in facilities and pipeline, TtR has considerable
        experience working with our clients to build out gathering systems and
        associated facilities.
      </p>
      <p>
        TtR works closely with our clients, contactors, and the producers to
        ensure the sites are designed efficiently. We use a collaborative
        approach to continuously improve our designs to reduce installation and
        construction costs throughout the campaign.
      </p>
      <h2 class="paragraph-header">Permian Crude Gathering System</h2>
      <p>
        This gathering system was a multi-phase project spanning multiple years
        to dramatically increase transportation and storage capacity for WTI
        crude. The project gathered oil from a producer's CTB facilities into
        mainline piping which transported the fluid to the main terminal. For
        outgoing transmission, there are four takeaway pipelines from the
        terminal with a total capacity of 850 MBPD. TtR provided conceptual,
        feasibility, detailed design, and construction management throughout the
        project.
      </p>

      <div class="image-list-container" fxLayout="row" fxLayout.lt-md="column">
        <div class="image-container" fxFlex.gt-md="1 1 80%">
          <img
            src="../../../../assets/img/permian-crude-gathering-system.webp"
            alt="pipeline terminal station with tanks"
          />
        </div>
        <div class="list-container" fxFlex.gt-md="1 1 20%">
          <h2 class="list-header">Key Features:</h2>
          <ul>
            <li>Takeaway capacity of 850 MBPD</li>
            <li>Over 100 central tank battery locations</li>
            <li>
              Over 140 miles of gathering lines with capacity greater than 450
              MBPD
            </li>
            <li>Complete design of two pump stations and one terminal</li>
            <li>Pump stations, 4,000 HP</li>
            <li>Crude storage tanks at terminal with 1,000 MBBL capacity</li>
          </ul>
        </div>
      </div>

      <h2 class="paragraph-header">Crude Storage Facilities</h2>
      <p>
        Our client has continued to grow their operations in the Midland and
        Delaware Basins. TtR has helped support this growth through crude
        storage facilities along their system. TtR has provided engineering and
        project support for multiple crude storage sites.
      </p>

      <div class="image-list-container" fxLayout="row" fxLayout.lt-md="column">
        <div class="image-container" fxFlex.gt-md="1 1 80%">
          <img src="../../../../assets/img/crude-storage.webp" />
        </div>
        <div class="list-container" fxFlex.gt-md="1 1 20%">
          <h2 class="list-header">Key Features:</h2>
          <ul>
            <li>Six crude storage sites</li>
            <li>Crude storage capacity over 900 MBBL</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
