<div class="wrapper">
  <div class="content-containter" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div class="content" fxLayout="column">
      <div id="image-1" class="image-container"></div>
      <h1 id="page-header">Health and Safety</h1>

      <p>
        Tetra Tech Rooney (TtR) is dedicated to maintaining a safe and healthy
        work environment for all employees and customers. TtR complies with or
        exceeds all applicable federal, state and local environmental, health
        and safety laws and regulations and industry-recognized best work
        practices. In addition, safety is inherent to our design approach.
        Utilizing our extensive operational and construction management
        experience, we incorporate safety considerations throughout all aspects
        of our design. TtR also has considerable experience developing safe work
        plans throughout construction and commissioning.
      </p>

      <div class="text-container">
        <p>
          Since inception, TtR continues to pursue health and safety excellence.
          Safety is at the forefront of our minds and designs. Our culture
          encourages constant safety awareness to design facilities that can be
          safely operated and maintained. As part of our commitment, TtR
          maintains good standing with our client connections and third-party
          compliance agencies. Our connections with these agencies ensures
          effectiveness and adherence with documentation and recordkeeping
          requirements including OSHA 300 reports, EMR standing, employee health
          and safety training, and appropriate insurance certificates. All of
          our accounts are managed under our original company name, Rooney
          Engineering Inc.
        </p>

        <p>TtR maintains program acceptance from:</p>

        <ul>
          <li>
            <a
              href="https://www.isnetworld.com"
              target="_blank"
              class="big-link link"
              >ISNetworld</a
            >
          </li>
          <li>
            <a
              href="https://www.veriforce.com"
              target="_blank"
              class="big-link link"
              >Veriforce/PEC</a
            >
          </li>
          <li>
            <a
              href="https://www.avetta.com"
              target="_blank"
              class="big-link link"
              >Avetta</a
            >
          </li>
        </ul>
        <p>
          For more information on TtR's HSE policies, please
          <a routerLink="/contact-us" class="link">contact us.</a>
        </p>
      </div>

      <div id="bottom-spacer"></div>
    </div>
    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
