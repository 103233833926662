import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { HomeComponent } from './home/home.component';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AboutHomeComponent } from './about/about-home/about-home.component';
import { AboutHistoryComponent } from './about/about-history/about-history.component';
import { AboutManagementComponent } from './about/about-management/about-management.component';
import { AboutSafetyComponent } from './about/about-safety/about-safety.component';
import { EngineeringArcFlashComponent } from './solutions/engineering/engineering-arc-flash/engineering-arc-flash.component';
import { EngineeringCivilStructComponent } from './solutions/engineering/engineering-civil-struct/engineering-civil-struct.component';
import { EngineeringCommercialComponent } from './solutions/engineering/engineering-commercial/engineering-commercial.component';
import { EngineeringDrawingsComponent } from './solutions/engineering/engineering-drawings/engineering-drawings.component';
import { EngineeringElectricalComponent } from './solutions/engineering/engineering-electrical/engineering-electrical.component';
import { EngineeringHydraulicsComponent } from './solutions/engineering/engineering-hydraulics/engineering-hydraulics.component';
import { EngineeringMappingComponent } from './solutions/engineering/engineering-mapping/engineering-mapping.component';
import { EngineeringPermittingComponent } from './solutions/engineering/engineering-permitting/engineering-permitting.component';
import { EngineeringPipelineComponent } from './solutions/engineering/engineering-pipeline/engineering-pipeline.component';
import { EngineeringFeasibilityComponent } from './solutions/engineering/engineering-feasibility/engineering-feasibility.component';
import { EngineeringTrenchlessComponent } from './solutions/engineering/engineering-trenchless/engineering-trenchless.component';
import { EngineeringFacilityComponent } from './solutions/engineering/engineering-facility/engineering-facility.component';
import { TechLaserScanningComponent } from './solutions/tech/tech-laser-scanning/tech-laser-scanning.component';
import { TechGeographicInformationComponent } from './solutions/tech/tech-geographic-information/tech-geographic-information.component';
import { TechGisDashboardComponent } from './solutions/tech/tech-gis-dashboard/tech-gis-dashboard.component';
import { TechAutomationComponent } from './solutions/tech/tech-automation/tech-automation.component';
import { IntegrityPermittingComponent } from './solutions/integrity/integrity-permitting/integrity-permitting.component';
import { IntegrityPipelineComponent } from './solutions/integrity/integrity-pipeline/integrity-pipeline.component';
import { ProjectManagementConstructionComponent } from './solutions/project-management/project-management-construction/project-management-construction.component';
import { ProjectManagementSupplyChainComponent } from './solutions/project-management/project-management-supply-chain/project-management-supply-chain.component';
import { ProjectManagementSchedulingComponent } from './solutions/project-management/project-management-scheduling/project-management-scheduling.component';
import { ProjectsFacilitiesComponent } from './projects/projects-facilities/projects-facilities.component';
import { ProjectsGatheringComponent } from './projects/projects-gathering/projects-gathering.component';
import { ProjectsIntegrityComponent } from './projects/projects-integrity/projects-integrity.component';
import { ProjectsPipelineComponent } from './projects/projects-pipeline/projects-pipeline.component';
import { MatButtonModule } from '@angular/material/button';
import { SolutionsHomeComponent } from './solutions/solutions-home/solutions-home.component';
import { ProjectsHomeComponent } from './projects/projects-home/projects-home.component';
import { ContactComponent } from './contact/component/contact.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ContactFormTempComponent } from './contact/contact-form-temp/contact-form-temp.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    HomeComponent,
    AboutHomeComponent,
    AboutHistoryComponent,
    AboutManagementComponent,
    AboutSafetyComponent,
    EngineeringArcFlashComponent,
    EngineeringCivilStructComponent,
    EngineeringCommercialComponent,
    EngineeringDrawingsComponent,
    EngineeringElectricalComponent,
    EngineeringHydraulicsComponent,
    EngineeringMappingComponent,
    EngineeringPermittingComponent,
    EngineeringPipelineComponent,
    EngineeringFeasibilityComponent,
    EngineeringTrenchlessComponent,
    EngineeringFacilityComponent,
    TechLaserScanningComponent,
    TechGeographicInformationComponent,
    TechGisDashboardComponent,
    TechAutomationComponent,
    IntegrityPermittingComponent,
    IntegrityPipelineComponent,
    ProjectManagementConstructionComponent,
    ProjectManagementSupplyChainComponent,
    ProjectManagementSchedulingComponent,
    ProjectsFacilitiesComponent,
    ProjectsGatheringComponent,
    ProjectsIntegrityComponent,
    ProjectsPipelineComponent,
    SolutionsHomeComponent,
    ProjectsHomeComponent,
    ContactComponent,
    ContactFormTempComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MatDividerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatIconModule,
  ],
  exports: [HomeComponent, AboutHomeComponent],
})
export class ContentModule {}
