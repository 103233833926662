<div class="wrapper">
  <div class="content-containter" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div class="content">
      <h1 id="page-header">Mapping and GIS</h1>

      <div class="text-image-container" fxLayout="row" fxLayout.lt-md="column">
        <div class="image-container" fxFlex="1 1 50%">
          <img src="../../../../../assets/img/mapping.webp" />
        </div>

        <div class="text-container" fxFlex="1 1 50%">
          <h2 class="paragraph-header">
            Technical Pipeline Routing and Mapping Solutions
          </h2>
          <p>
            Tetra Tech Rooney (TtR) has extensive expertise in providing
            superior Pipeline Mapping & GIS Services along with innovative
            WebApp solutions within the Oil & Gas industry. TtR has a proven
            track record of supplying high quality engineering-based pipeline
            routing, along with environmental permitting, survey, right-of-way
            and land acquisition support for major linear and gathering pipeline
            projects across the nation. Our project experience covers a wide
            range of route conditions, from rural landscapes to highly congested
            urban settings that include diverse terrain and design challenges,
            such as densely populated areas, rugged mountain ranges and lowland
            wetlands, which require critical thinking and experience to
            traverse. TtR's mapping team overcomes the geographical and
            population related challenges with inventive and practical problem
            solving to complete projects of varying complexity. Our mapping team
            utilizes AutoCAD, Microstation, ArcGIS and other industry leading
            software packages to provide drawings, customized databases and
            other mapping solutions to fit the ever-changing needs and data
            requirements of our clients.
          </p>
          <h2 class="paragraph-header">Mapping and GIS Services</h2>
          <ul class="standard-list">
            <li>Engineering based pipeline routing and design</li>
            <li>
              Integration of comprehensive databases for project management and
              control
            </li>
            <li>
              Drastic time and expense reduction through the use of digital
              platforms
            </li>
            <li>
              Highly skilled in creating detailed alignment sheets, crossing
              drawings, permit drawings and various construction drawings
            </li>
            <li>Ortho-rectification of aerial photography</li>
            <li>
              Extensive knowledge of construction operations including bored
              crossing methods and Horizontal Directional Drills (HDD)
            </li>
            <li>Integration of LIDAR and ground-based survey</li>
            <li>
              Advanced expertise in GIS and CAD platforms, providing flexibility
              to the client
            </li>
            <li>
              Provide mapping support through project life-cycle; FEED,
              permitting, bidding, construction, as-built and integrity.
            </li>
            <li>
              Well versed in the use of Bluesky Skyline & Lightning, and New
              Century SheetCutter
            </li>
            <li>Innovative and customizable WebApp solutions</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
