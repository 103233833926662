import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-project-management-construction',
  templateUrl: './project-management-construction.component.html',
  styleUrls: ['./project-management-construction.component.css'],
})
export class ProjectManagementConstructionComponent implements OnInit {
  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('Construction Support - Tetra Tech Rooney');
  }
}
