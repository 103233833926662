import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-projects-integrity',
  templateUrl: './projects-integrity.component.html',
  styleUrls: ['./projects-integrity.component.css'],
})
export class ProjectsIntegrityComponent implements OnInit {
  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('Integrity Projects - Tetra Tech Rooney');
  }
}
