<div class="wrapper">
  <div class="content-containter" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div class="content">
      <h1 id="page-header">Hydraulics and Pipe Stress Analysis</h1>
      <h2 class="paragraph-header">
        Hydraulic Modeling to Optimize Pipeline Transmission Systems
      </h2>
      <p>
        Many pipelines are not operating at the optimal efficiency which can
        limit flow capabilities, cause higher operational costs, and increase
        downtime due to mechanical repairs. Hydraulic modeling can ensure that
        pumps, control valves and operational schemes are properly adjusted to
        keep the pipeline costs and repairs to a minimum.
      </p>
      <p>
        Tetra Tech Rooney is fluent in multiple industry accepted hydraulic
        programs including: DNVGL products (Synergi Pipeline Simulator, Synergi
        Gas), AFT products (Fathom, Impulse, Arrow), Pipeline Studio, PIPE-FLO
        PROFESSIONAL and various in-house programs that have been developed over
        the years. These tools allow us to complete a wide range of hydraulic
        modeling services, including liquid, gas or multiphase flows in either
        steady state or dynamic modeling applications.
      </p>
      <p>
        We have extensive expertise in hydraulic modeling for the last 40 years
        including but not limited to: pipeline sizing, optimal pump station
        placement, line fill calculations, DRA modeling, startup / shutdown
        sequencing, control system modeling, SCADA integration, operational cost
        optimization, horsepower calculations, identifying slack line flow and
        corrective measures, surge relief system design, pig tracking, and pump
        operating point mapping.
      </p>
      <p>
        Our team is led by a group of talented hydraulic engineers, that have a
        proven record of building efficient workflows and techniques for
        providing accurate and reliable hydraulic modeling in a cost-effective
        manner.
      </p>
      <div class="image-container">
        <img src="../../../../../assets/img/hydraulic-graph.webp" />
      </div>
      <h2 class="paragraph-header">Pipe Stress Analysis</h2>
      <p>
        Tetra Tech Rooney staff has experience in conducting stress analysis
        modeling for both static and dynamic situations. We evaluate static load
        requirements based on pipe layout, valve location, vendor provided
        equipment information, space constraints and access requirements. Also,
        we consider the dynamic stress that are generated by both fluid dynamics
        and equipment forces, such as rotating pumps, reciprocating pumps, and
        associated vibrations. Tetra Tech Rooney has multiple engineers fully
        trained in the use of CAESAR II, the industry standard for pipe stress
        analysis.
      </p>
      <div id="three-image-container" fxLayout="row">
        <div id="image-1" fxFlex="1 1 33%">
          <img
            src="../../../../../assets/img/pipe-stress-1.webp"
            alt="3d stress model"
          />
        </div>
        <div id="image-2" fxFlex="1 1 33%">
          <img
            src="../../../../../assets/img/pipe-stress-2.webp"
            alt="3d stress model"
          />
        </div>
        <div id="image-3" fxFlex="1 1 33%">
          <img
            src="../../../../../assets/img/pipe-stress-3.webp"
            alt="3d stress model"
          />
        </div>
      </div>
    </div>
    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
