<div class="wrapper">
  <div class="header-image"></div>

  <div class="content-containter" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div id="center-column" fxFlex fxLayout="column">
      <div
        class="content"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="space-between"
      >
        <div id="box-one" class="box" fxLayout="column" fxFlex.gt-md="1 1 45%">
          <div class="icon-box" fxLayoutAlign="center center">
            <mat-icon inline="true">call_end</mat-icon>
          </div>
          <div
            class="content-box"
            fxLayoutAlign="space-between center"
            fxLayout="column"
          >
            <h1 class="paragraph-header text-header">Contact Information</h1>

            <div id="contact-info-container">
              <div
                class="info-block"
                fxLayout="row"
                fxLayoutAlign="space-between"
              >
                <div class="key" fxFlex>Phone:</div>
                <div class="divider"></div>
                <div class="value" fxFlex>303-792-5911</div>
              </div>
              <div
                class="info-block"
                fxLayout="row"
                fxLayoutAlign="space-between"
              >
                <div class="key" fxFlex>Address:</div>
                <div class="value" fxFlex>
                  115 Inverness Drive East Suite 300
                </div>
              </div>
              <div
                class="info-block"
                fxLayout="row"
                fxLayoutAlign="space-between"
              >
                <div class="key" fxFlex>City, State:</div>
                <div class="divider"></div>
                <div class="value" fxFlex>Englewood, CO</div>
              </div>
              <div
                class="info-block"
                fxLayout="row"
                fxLayoutAlign="space-between"
              >
                <div class="key" fxFlex>Zip:</div>
                <div class="divider"></div>
                <div class="value" fxFlex>80112</div>
              </div>
            </div>
          </div>
        </div>

        <div id="column-spacer"></div>
        <div id="box-two" class="box" fxFlex.gt-md="1 1 45%">
          <div class="icon-box" fxLayoutAlign="center center">
            <mat-icon inline="true">event</mat-icon>
          </div>
          <div
            class="content-box"
            fxLayoutAlign="center center"
            fxLayout="column"
          >
            <h1 class="paragraph-header text header">Book a Meeting</h1>
            <div id="joe-info" fxLayout="row">
              <div id="pic" fxFlex="1 1 50%">
                <img src="../../../../assets/img/joe-test.webp" />
              </div>
              <div id="name-title-container" fxFlex="1 1 50%">
                <p id="name">Joe Meier</p>
                <p id="title">Director of Business Development</p>
              </div>
            </div>
            <button
              id="meeting-button"
              mat-raised-button
              onclick="window.open('https://outlook.office365.com/owa/calendar/TetraTech6@tetratechinc.onmicrosoft.com/bookings/'); return false;"
            >
              Book a meeting with Joe
            </button>
          </div>
        </div>
      </div>
      <div class="spacer"></div>
    </div>

    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
