import { Component, OnInit } from '@angular/core';
import { Form } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Message } from '../models/message';
import { ContactService } from '../service/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {
  constructor(
    private contactService: ContactService,
    private titleService: Title
  ) {}

  message = new Message();
  state: string = 'unSubmitted';

  ngOnInit(): void {
    this.titleService.setTitle('Contact Us - Tetra Tech Rooney');
  }

  onSubmit(): void {
    this.state = 'loading';
    console.log(this.state);
    this.contactService.sendMessage(this.message, (success: boolean) => {
      if (success) {
        this.state = 'submitted';
        console.log(this.state);
      } else {
        this.state = 'error';
      }
    });
  }
}
