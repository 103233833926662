<div class="wrapper">
  <div class="content-containter" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div class="content" fxFlex="1 1 60%" fxFlex.lt-md="1 1 90%">
      <div class="spacer"></div>
      <mat-divider></mat-divider>
      <div class="splash-text-container" fxLayoutAlign="center center">
        <span id="splash-text" [ngStyle.lt-md]="{ 'font-size.pt': 25 }"
          >ACHEIVING EXCELLENCE SINCE 1980</span
        >
      </div>
      <mat-divider></mat-divider>
      <div class="spacer"></div>

      <div class="image-container">
        <img
          src="../../../../assets/img/yukon-river-crossing.webp"
          alt="yukon river crossing"
        />
      </div>

      <h1 id="page-header">Our History</h1>
      <p>
        Tetra Tech Rooney (TtR) was founded in 1980 as Rooney Engineering, Inc.
        when a need arose for a saltwater disposal system in the Williston Basin
        oil and gas fields of North Dakota and Montana. TtR rose to the
        challenge by successfully designing and implementing a unified gathering
        system for transporting water via pipeline and disposing it into
        abandoned oil and gas wells. Since then, we have maintained focus on our
        clients which has enabled us to provide a diversified portfolio of
        comprehensive engineering services for the oil and gas industry. In
        addition, we were able to expand with new offices in Casper, WY and
        Billings, MT to provide local client support. Valued for our industry
        expertise and unique range of services, Rooney Engineering, Inc. was
        acquired by Tetra Tech in 2012 to become Tetra Tech Rooney. Today, our
        portfolio of work spans throughout the United States including work in
        both Alaska and Hawaii. TtR continues that original vision to be a
        dynamic and innovative team seeking cost-effective ways to serve our
        clients.
      </p>
      <div class="spacer"></div>
    </div>
    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
