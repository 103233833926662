import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-engineering-trenchless',
  templateUrl: './engineering-trenchless.component.html',
  styleUrls: ['./engineering-trenchless.component.css'],
})
export class EngineeringTrenchlessComponent implements OnInit {
  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('Trenchless Crossings - Tetra Tech Rooney');
  }
}
