<div class="wrapper">
  <div class="header-image"></div>

  <div class="content-containter" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div
      class="content"
      fxFlex="1 1 60%"
      fxFlex.lt-md="1 1 90%"
      fxLayout="row"
      fxLayout.lt-md="column"
    >
      <div
        *ngIf="this.state == 'loading'"
        fxFlex="1 1 70%"
        fxLayoutAlign="center center"
      >
        <mat-spinner></mat-spinner>
      </div>
      <div *ngIf="this.state == 'submitted'" fxFlex="1 1 70%">
        <h2 class="paragraph-header">Thank you for your submission!</h2>
        <h2 class="paragraph-header">Submission details:</h2>
        <p class="contact-info">
          First Name: {{ message.firstName }}<br />
          Last Name: {{ message.lastName }}<br />Company: {{ message.company
          }}<br />Email: {{ message.email }}<br />Message: {{ message.message }}
        </p>
      </div>
      <form
        *ngIf="this.state == 'unSubmitted'"
        (ngSubmit)="onSubmit()"
        #messageForm="ngForm"
        fxFlex="1 1 70%"
      >
        <div id="header-text">
          <h1 id="page-header">Send Us a Message</h1>
        </div>
        <div class="form-container" fxLayout="column">
          <mat-form-field appearance="fill">
            <mat-label>First Name</mat-label>
            <input
              matInput
              type="text"
              class="form-input"
              id="first-mame"
              [(ngModel)]="message.firstName"
              name="firstName"
              required
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Last Name</mat-label>
            <input
              matInput
              type="text"
              class="form-input"
              id="last-name"
              [(ngModel)]="message.lastName"
              name="lastName"
              required
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Company</mat-label>
            <input
              matInput
              type="text"
              class="form-input"
              id="company"
              [(ngModel)]="message.company"
              name="company"
              required
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Email Address</mat-label>
            <input
              matInput
              type="email"
              class="form-input"
              id="email"
              [(ngModel)]="message.email"
              name="email"
              required
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Message</mat-label>
            <textarea
              matInput
              type="text"
              class="form-input"
              id="message"
              [(ngModel)]="message.message"
              name="message"
            ></textarea>
          </mat-form-field>
          <input
            id="call-mobile"
            type="checkbox"
            name="callMobile"
            value="1"
            tabindex="-1"
            autocomplete="off"
            [(ngModel)]="message.callMobile"
          />
        </div>
        <div id="submit-button-container" fxLayoutAlign="center">
          <button
            mat-raised-button
            id="submit-button"
            class="button"
            type="submit"
            [disabled]="!messageForm.form.valid"
          >
            Submit
          </button>
        </div>
      </form>
      <div id="spacer-column" fxFlex></div>
      <div id="right-column" fxFlex.gt-md="1 1 20%">
        <h1 class="paragraph-header text-header">Contact Information</h1>
        <p class="contact-info">
          Phone: 303-792-5911<br />115 Inverness Drive East Suite 300
          <br />Englewood, CO<br />
          80112
        </p>
        <h1 class="paragraph-header text header">Book a Meeting</h1>
        <div id="joe-info" fxLayout="row">
          <div id="pic" fxFlex="1 1 50%">
            <img src="../../../../assets/img/joe-meier.webp" />
          </div>
          <div id="name-title-container" fxFlex="1 1 50%">
            <p id="name">Joe Meier</p>
            <p id="title">Director of Business Development</p>
          </div>
        </div>
        <a
          id="meeting-link"
          href="https://outlook.office365.com/owa/calendar/TetraTech6@tetratechinc.onmicrosoft.com/bookings/"
          target="blank"
          >Book a meeting with Joe</a
        >
      </div>
    </div>
    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
