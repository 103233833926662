import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-project-management-supply-chain',
  templateUrl: './project-management-supply-chain.component.html',
  styleUrls: ['./project-management-supply-chain.component.css'],
})
export class ProjectManagementSupplyChainComponent implements OnInit {
  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('Supply Chain - Tetra Tech Rooney');
  }
}
