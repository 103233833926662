<div class="wrapper">
  <div class="header-image"></div>

  <div class="content-container" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div
      class="content-block"
      fxLayout="column"
      fxFlex="1 1 60%"
      fxFlex.lt-md="1 1 90%"
    >
      <div class="top-block" fxLayout="row" fxLayout.lt-md="column">
        <div class="left-block" fxFlex.gt-sm="1 1 40%">
          <h2 class="paragraph-header">
            <a
              class="typical-link"
              href="https://www.tetratech.com/en/oil-and-gas"
              target="blank"
              >Tetra Tech</a
            >
          </h2>
          <p>
            Tetra Tech is a leading provider of consulting and engineering
            services worldwide. We are a diverse company, including individuals
            with expertise in science, research, engineering, construction, and
            information technology. Our strength is in collectively providing
            integrated services—delivering the best solutions to meet our
            clients' needs. Tetra Tech’s innovative solutions help our clients
            address their water, environment, sustainable infrastructure,
            resource management, renewable energy, and international development
            challenges. We are proud to be home to leading technical experts in
            every sector and to use that expertise throughout the project life
            cycle. Our commitment to safety is ingrained in our culture and at
            the forefront of every project.
          </p>

          <h2 class="paragraph-header">Locations</h2>
          <p>Our offices are located in:</p>
          <ul>
            <li>Denver, CO</li>
            <li>Casper, WY</li>
            <li>Billings, MT</li>
          </ul>
          <p>
            We also have staff strategically located in Minnesota, Utah and
            Texas to bolster our regional support footprint with our clients.
            Tetra Tech also has 450 offices worldwide.
          </p>
          <div id="badge-container">
            <img id="badge" src="../../../../assets/img/ENR.webp" />
          </div>
        </div>
        <div class="center-block" fxFlex fxHide.lt-md></div>
        <div class="right-block" fxFlex="1 2 40%">
          <h2 class="paragraph-header">Tetra Tech Rooney</h2>
          <p>
            As a Tetra Tech Company Business Unit, Tetra Tech Rooney (TtR)
            specializes in petroleum and natural gas transmission pipelines ,
            pumping, compression, metering, processing , and storage systems.
            Our team of multidisciplinary engineers and designers have
            successfully completed a large variety of projects, including
            pipelines, pump stations, meter stations, tank farms, rail
            loading/unloading, and blending facilities. With over forty years of
            history in the midstream sector, we have the skill, depth of
            resources, and technical talent to help our clients address their
            petroleum transmission challenges. While our history is rooted in
            oil and gas, our future supports a diverse range of energy sources
            and industrial applications. Tetra Tech Rooney is your partner for
            safe, efficient, cost-effective, and practical designs throughout
            the life cycle of your project.
          </p>
          <h2 class="paragraph-header">Vision</h2>
          <p>
            Our future begins with our team. It is what makes everything
            possible and is the source of our success. As a team, we are united
            with the following characteristics:
          </p>

          <ul>
            <li>Safety is at the forefront of our minds and our designs</li>
            <li>We seek innovative solutions</li>
            <li>We take a vested approach to our clients’ challenges</li>
            <!-- prettier-ignore-->
            <li>We are highly skilled in our engineering and technical fundamentals</li>
            <!-- prettier-ignore-->
            <li>We seek out professional licensure throughout most of the US and further development opportunities</li>
            <!-- prettier-ignore-->
            <li>We are responsive to our clients needs and take proactive measures to improve service</li>
            <li>We work to our client’s daily schedule and project timeline</li>
            <li>We listen to our clients and strive to serve</li>
            <!-- prettier-ignore-->
            <li>We are efficient with our time and conduct our business ethically</li>
            <li>We attract and retain the best talent in the industry</li>
            <!-- prettier-ignore-->
            <li>We deliver projects that meet the scope, budget, and schedule objectives</li>
            <li>We exceed expectations</li>
            <li>We provide safe, constructible, and compliant designs</li>
            <li>We seek methods for continuous improvement and efficiency</li>
            <!-- prettier-ignore-->
            <li>We provide a customer focused approach throughout the asset lifecycle</li>
          </ul>
        </div>
      </div>
      <div
        class="picture-grid-container"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="space-between"
      >
        <div class="link-card" fxFlex.gt-sm="1 1 30%">
          <a fxLayout="column" routerLink="/about/history">
            <div class="card-title" fxFlex="1 1 10%">
              <h2 class="paragraph-header">History</h2>
            </div>

            <div class="image-container" id="image1" fxFlex="1 1 100%"></div>
          </a>
        </div>
        <div class="link-card" fxFlex.gt-sm="1 1 30%">
          <a fxLayout="column" routerLink="/about/management">
            <div class="card-title" fxFlex="1 1 10%">
              <h2 class="paragraph-header">Management Team</h2>
            </div>

            <div class="image-container" id="image2" fxFlex="1 1 100%"></div>
          </a>
        </div>
        <div class="link-card" fxFlex.gt-sm="1 1 30%">
          <a fxLayout="column" routerLink="/about/safety">
            <div class="card-title" fxFlex="1 1 10%">
              <h2 class="paragraph-header">Health and Safety</h2>
            </div>

            <div class="image-container" id="image3" fxFlex="1 1 100%"></div>
          </a>
        </div>
      </div>
    </div>

    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
