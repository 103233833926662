import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './content/home/home.component';
import { DefaultLayoutComponent } from './default/default-layout/default-layout.component';
import { AboutHomeComponent } from './content/about/about-home/about-home.component';
import { AboutHistoryComponent } from './content/about/about-history/about-history.component';
import { AboutManagementComponent } from './content/about/about-management/about-management.component';
import { AboutSafetyComponent } from './content/about/about-safety/about-safety.component';
import { EngineeringArcFlashComponent } from './content/solutions/engineering/engineering-arc-flash/engineering-arc-flash.component';
import { EngineeringCivilStructComponent } from './content/solutions/engineering/engineering-civil-struct/engineering-civil-struct.component';
import { EngineeringCommercialComponent } from './content/solutions/engineering/engineering-commercial/engineering-commercial.component';
import { EngineeringDrawingsComponent } from './content/solutions/engineering/engineering-drawings/engineering-drawings.component';
import { EngineeringElectricalComponent } from './content/solutions/engineering/engineering-electrical/engineering-electrical.component';
import { EngineeringFacilityComponent } from './content/solutions/engineering/engineering-facility/engineering-facility.component';
import { EngineeringHydraulicsComponent } from './content/solutions/engineering/engineering-hydraulics/engineering-hydraulics.component';
import { EngineeringMappingComponent } from './content/solutions/engineering/engineering-mapping/engineering-mapping.component';
import { EngineeringPermittingComponent } from './content/solutions/engineering/engineering-permitting/engineering-permitting.component';
import { EngineeringPipelineComponent } from './content/solutions/engineering/engineering-pipeline/engineering-pipeline.component';
import { EngineeringFeasibilityComponent } from './content/solutions/engineering/engineering-feasibility/engineering-feasibility.component';
import { EngineeringTrenchlessComponent } from './content/solutions/engineering/engineering-trenchless/engineering-trenchless.component';
import { TechLaserScanningComponent } from './content/solutions/tech/tech-laser-scanning/tech-laser-scanning.component';
import { TechGeographicInformationComponent } from './content/solutions/tech/tech-geographic-information/tech-geographic-information.component';
import { TechGisDashboardComponent } from './content/solutions/tech/tech-gis-dashboard/tech-gis-dashboard.component';
import { TechAutomationComponent } from './content/solutions/tech/tech-automation/tech-automation.component';
import { IntegrityPermittingComponent } from './content/solutions/integrity/integrity-permitting/integrity-permitting.component';
import { IntegrityPipelineComponent } from './content/solutions/integrity/integrity-pipeline/integrity-pipeline.component';
import { ProjectManagementConstructionComponent } from './content/solutions/project-management/project-management-construction/project-management-construction.component';
import { ProjectManagementSchedulingComponent } from './content/solutions/project-management/project-management-scheduling/project-management-scheduling.component';
import { ProjectManagementSupplyChainComponent } from './content/solutions/project-management/project-management-supply-chain/project-management-supply-chain.component';
import { ProjectsFacilitiesComponent } from './content/projects/projects-facilities/projects-facilities.component';
import { ProjectsGatheringComponent } from './content/projects/projects-gathering/projects-gathering.component';
import { ProjectsIntegrityComponent } from './content/projects/projects-integrity/projects-integrity.component';
import { ProjectsPipelineComponent } from './content/projects/projects-pipeline/projects-pipeline.component';
import { SolutionsHomeComponent } from './content/solutions/solutions-home/solutions-home.component';
import { ProjectsHomeComponent } from './content/projects/projects-home/projects-home.component';
import { ContactComponent } from './content/contact/component/contact.component';
import { ContactFormTempComponent } from './content/contact/contact-form-temp/contact-form-temp.component';

const routes: Routes = [
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'about/home', component: AboutHomeComponent },
      { path: 'about/history', component: AboutHistoryComponent },
      { path: 'about/management', component: AboutManagementComponent },
      { path: 'about/safety', component: AboutSafetyComponent },
      { path: 'solutions/solutions-home', component: SolutionsHomeComponent },
      {
        path: 'solutions/engineering/arc-flash',
        component: EngineeringArcFlashComponent,
      },
      {
        path: 'solutions/engineering/civil-structural',
        component: EngineeringCivilStructComponent,
      },
      {
        path: 'solutions/engineering/commercial',
        component: EngineeringCommercialComponent,
      },
      {
        path: 'solutions/engineering/drawings',
        component: EngineeringDrawingsComponent,
      },
      {
        path: 'solutions/engineering/electrical',
        component: EngineeringElectricalComponent,
      },
      {
        path: 'solutions/engineering/facility',
        component: EngineeringFacilityComponent,
      },
      {
        path: 'solutions/engineering/hydraulics',
        component: EngineeringHydraulicsComponent,
      },
      {
        path: 'solutions/engineering/mapping',
        component: EngineeringMappingComponent,
      },
      {
        path: 'solutions/engineering/permitting',
        component: EngineeringPermittingComponent,
      },
      {
        path: 'solutions/engineering/pipeline',
        component: EngineeringPipelineComponent,
      },
      {
        path: 'solutions/engineering/feasability',
        component: EngineeringFeasibilityComponent,
      },
      {
        path: 'solutions/engineering/trenchless',
        component: EngineeringTrenchlessComponent,
      },
      {
        path: 'solutions/tech/laser-scanning',
        component: TechLaserScanningComponent,
      },
      {
        path: 'solutions/tech/geographic-information',
        component: TechGeographicInformationComponent,
      },
      {
        path: 'solutions/tech/gis-dashboard',
        component: TechGisDashboardComponent,
      },
      { path: 'solutions/tech/automation', component: TechAutomationComponent },
      {
        path: 'solutions/integrity/permitting',
        component: IntegrityPermittingComponent,
      },
      {
        path: 'solutions/integrity/pipeline',
        component: IntegrityPipelineComponent,
      },
      {
        path: 'solutions/project-management/construction',
        component: ProjectManagementConstructionComponent,
      },
      {
        path: 'solutions/project-management/scheduling',
        component: ProjectManagementSchedulingComponent,
      },
      {
        path: 'solutions/project-management/supply-chain',
        component: ProjectManagementSupplyChainComponent,
      },
      {
        path: 'projects/home',
        component: ProjectsHomeComponent,
      },
      {
        path: 'projects/facilities',
        component: ProjectsFacilitiesComponent,
      },
      {
        path: 'projects/gathering',
        component: ProjectsGatheringComponent,
      },
      {
        path: 'projects/integrity',
        component: ProjectsIntegrityComponent,
      },
      {
        path: 'projects/pipeline',
        component: ProjectsPipelineComponent,
      },
      { path: 'contact-us', component: ContactFormTempComponent },
    ],
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
