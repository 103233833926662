<link
  href="https://fonts.googleapis.com/icon?family=Material+Icons"
  rel="stylesheet"
/>
<div class="wrapper">
  <div class="header-separation"></div>
  <div class="site-toolbar">
    <div class="container" fxLayout="row">
      <div class="left-buffer" fxFlex="1 3 20%" fxFlex.lt-sm="5%"></div>
      <div class="logo" fxFlex fxLayoutAlign="start center">
        <a routerLink="" [ngStyle.lt-sm]="{ 'width.px': 150 }">
          <img
            id="logo-pic"
            src="../../../assets/img//Tt_Rooney_Logo_blu.webp"
            alt="Tetra Tech Logo"
            [ngStyle.lt-sm]="{ 'width.px': 150 }"
          />
        </a>
      </div>
      <div
        class="hamburger-menu-container"
        fxHide="true"
        fxShow.lt-md="true"
        fxFlex="1 1 15%"
        fxLayoutAlign="end center"
        fxLayoutAlign.lt-sm="start center"
      >
        <button
          id="hamburger-button"
          class="material-icons"
          mat-mat-icon-button
          [matMenuTriggerFor]="menu"
        >
          <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <a mat-menu-item routerLink="about/home">ABOUT</a>
          <a mat-menu-item routerLink="solutions/solutions-home">SOLUTIONS</a>
          <a mat-menu-item routerLink="projects/home">PROJECTS</a>
          <a mat-menu-item routerLink="contact-us">CONTACT US</a>
        </mat-menu>
      </div>
      <div
        class="drop-down-container"
        fxFlex
        fxLayout="row"
        fxShow="true"
        fxHide.lt-md="true "
        fxLayoutAlign="end center"
      >
        <!--About button and menu-->
        <button class="drop-down" mat-button routerLink="about/home">
          ABOUT
        </button>

        <!-- Solutions button and menu -->
        <button
          class="drop-down"
          mat-button
          routerLink="solutions/solutions-home"
        >
          SOLUTIONS
        </button>

        <!--Projects button and menu-->
        <button class="drop-down" mat-button routerLink="projects/home">
          PROJECTS
        </button>

        <span class="drop-down">
          <a mat-button class="drop-down" routerLink="contact-us">CONTACT US</a>
        </span>
      </div>
      <div
        class="right-buffer"
        fxFlex="1 5 20%"
        fxShow="true"
        fxHide.lt-sm="true"
      ></div>
    </div>
    <mat-divider class="divider"></mat-divider>
  </div>
</div>
