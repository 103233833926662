<div class="wrapper">
  <div class="content-containter" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div class="content" fxLayout="column">
      <h1 id="page-header">Pipeline Engineering</h1>
      <div class="image-container"></div>
      <div class="text-container">
        <p>
          TtR provides high quality, engineering-based pipeline design for
          linear and gathering system pipeline projects across the nation. Our
          expertise has been applied across a wide range of route conditions ,
          from rugged and rural landscapes to highly congested urban settings.
        </p>
        <p>
          When consulted to evaluate pipeline routing, TtR considers many
          factors which include safe constructability, cost, schedule, distance,
          and areas with major permitting issues.
        </p>
        <p>
          TtR is also experienced with mainline block valve sites that are often
          part of the pipeline system. This includes the development of new
          valve sites, modification and expansion of existing valve sites,
          access and ROW requirements, remote communications, and determining
          power supply availability for valve actuation and instrumentation. TtR
          has experience in both below grade and above grade block valve site
          development.
        </p>
        <p>
          Our design expertise includes the development of full engineering
          packages including alignment sheets, plan & profile drawings,
          construction and demolition piping plan and section drawings, typical
          details, and isometric drawings.
        </p>
        <p>
          Our
          <a class="typical-link" routerLink="/projects/pipeline"
            >Pipeline Projects Page</a
          >
          contains a few recent examples of some of our work.
        </p>
      </div>
    </div>
    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
