<div class="wrapper">
  <div class="content-containter" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div class="content">
      <h1 id="page-header">Commercial and Due Diligence</h1>

      <div class="image-container"></div>
      <h2 class="paragraph-header">
        Vital Review for Oil and Gas Infrastructure
      </h2>
      <p>
        While considering the purchase of an existing facility or pipeline
        system, it is essential to ensure that the correct documentation has
        been captured and filed. Tetra Tech Rooney has extensive experience
        performing due diligence of existing records to confirm that existing
        systems were designed with sound engineering methods, built as planned,
        and hold valid documentation for regulatory compliance.
      </p>
      <p>
        From an individual spot check to a full records audit, we can provide
        any level of assessment. Some of the documentation reviewed in a full
        audit includes:
      </p>
      <ul>
        <li>Welder Qualifications and Testing Records</li>
        <li>MTR Reports</li>
        <li>Pipe Specs</li>
        <li>Integrity Management Plans</li>
        <li>Hydrotest Device & Completion Records</li>
        <li>Emergency Response Plans</li>
        <li>Weld & Heat Maps</li>
        <li>Spill Prevention, Control, and Countermeasure Plans</li>
      </ul>
      <p>
        In addition to physical records, Tetra Tech Rooney has experience in
        reviewing integrity management programs (IMPs), high consequence area
        (HCA) analyses, and piggability of gathering lines for both cleaning
        pigs and in-line inspection (ILI) tools.
      </p>
    </div>
    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
