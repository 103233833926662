import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-engineering-facility',
  templateUrl: './engineering-facility.component.html',
  styleUrls: ['./engineering-facility.component.css'],
})
export class EngineeringFacilityComponent implements OnInit {
  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('Facility Engineering - Tetra Tech Rooney');
  }
}
