import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-engineering-arc-flash',
  templateUrl: './engineering-arc-flash.component.html',
  styleUrls: ['./engineering-arc-flash.component.css'],
})
export class EngineeringArcFlashComponent implements OnInit {
  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('Arc Flash - Tetra Tech Rooney');
  }
}
