<div class="wrapper">
  <div class="content-containter" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div class="content">
      <h1 id="page-header">Permitting Support</h1>
      <div id="image-1" class="image-container"></div>
      <h2 class="paragraph-header">
        Tailored Environmental and Permitting to Suit Project Needs
      </h2>
      <p>
        We understand that every project is unique and align our environmental
        services commensurate with the nature and extent of project-specific
        requirements, from capital investment projects to operations and
        maintenance projects.
      </p>
      <p>
        Tetra Tech has provided environmental support to midstream clients for
        over thirty-five years. Our staff have gained exceptional first-hand
        experience managing large, multi-disciplinary project teams and
        successfully managing and performing comprehensive federal, state, and
        local environmental permitting and regulatory analysis on hundreds of
        projects totaling over 16,000 miles of pipelines and hundreds of major
        aboveground facilities, nationwide (45 states) and across Canada.
      </p>
      <p>
        We can perform a full spectrum of environmental permitting services,
        including fatal flaw studies, strategic permitting plans, routing and
        siting studies, alternatives analyses, cumulative impact assessments,
        agency consultation, public outreach programs, field surveys, technical
        reporting, permitting, best management plans, resource-specific
        mitigation plans, compliance training, and inspection programs.
      </p>
    </div>
    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
