import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Message } from '../models/message';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  /* BACKEND_URL = 'http://localhost:3000/api/message'; */
  BACKEND_URL = environment.BACKEND_URL;
  constructor(private http: HttpClient) {}

  async sendMessage(message: Message, callback: any) {
    this.http.post<any>(this.BACKEND_URL, message).subscribe((resp) => {
      if (resp.message) {
        callback(true);
      } else {
        callback(false);
      }
    });
    return false;
  }
}
