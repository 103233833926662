<div class="wrapper">
  <div class="content-containter" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div class="content">
      <h1 id="page-header">Detailed Design Drawings</h1>
      <h2 class="paragraph-header">
        Accurate Pipeline and Facility Documentation
      </h2>
      <p>
        Whether your facility is a legacy asset or was newly acquired, knowing
        the accuracy of your drawings is important. Tetra Tech Rooney (TtR) has
        decades of experience in both design and construction support of oil &
        gas facilities. TtR will take the construction drawings and physically
        walk down the facility to ensure that construction records are accurate.
        These critical drawings will include PFDs, P&IDs, piping plans and
        sections, electrical one-line diagrams, and hazardous area
        classification plans.
      </p>
      <p>
        TtR has provided construction support and as-built documentation for
        dozens of our clients' facilities and pipelines. For facilities, TtR
        will obtain field information to provide as-built redlines and turn them
        into as-built CAD files. On the pipeline side, TtR utilizes survey data
        in order to capture the location of welds, points of inflection (PIs),
        material changes, and any other pipeline data that is required by the
        client. TtR provides as-built alignment sheets, as-built permit crossing
        drawings for railroads, roads, foreign lines, and public utilities, and
        can deliver a complete as-built database ready for import into the
        operator’s PODS or UPDM systems.
      </p>

      <div class="text-container">
        <h2 class="paragraph-header">Advanced CAD and GIS Capabilities</h2>
        <p>
          Using the latest software technology such as Civil3D, AutoCAD Plant
          3D, CADworx Plant Professional, CADworx Structure, and Navisworks 3D
          models our designers and engineers prepare a product that is focused
          on our client's standards and objectives. Our 3D models are ideal to
          create collaborative and productive design reviews, identify potential
          clashes, support constructability reviews, and easily migrate into
          digital twin operational tools for our clients.
        </p>
        <p>
          Our Mapping and GIS professionals use the latest ESRI software such as
          ArcMap, ArcGIS Pro, ArcGIS Online, WebApp Developer, and third-party
          extensions Bluesky Lightning and New Century SheetCutter. In addition
          to the previous CAD tools, our team utilizes Global Mapper and Bluesky
          Skyline.
        </p>
      </div>
      <div class="image-container">
        <img
          src="../../../../../assets/img/cad-and-gis-capabilities-3d-model.webp"
          alt="3d model of terminal station"
        />
      </div>
    </div>
    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
