import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-engineering-pipeline',
  templateUrl: './engineering-pipeline.component.html',
  styleUrls: ['./engineering-pipeline.component.css'],
})
export class EngineeringPipelineComponent implements OnInit {
  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('Pipeline Engineering - Tetra Tech Rooney');
  }
}
