<div class="wrapper">
  <div class="content-containter" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div class="content">
      <h1 id="page-header">Digital Twin Facility and 3D Laser Scanning</h1>
      <h2 class="paragraph-header">
        Digital Twin - Customized Asset Management Solutions
      </h2>
      <p>
        Traditionally, our 3D models have been built to create as-built
        drawings. Once those drawings are issued though, they no longer have a
        direct connection to the actual facility and can become quickly
        out-of-date. With our Digital Twin Services, we are leveraging our
        conventional data collection methods such as as-built and laser
        scanning, while maintaining the accuracy of that data throughout the
        asset's lifecycle.
      </p>
      <p>
        A Digital Twin is a representation of a facility used to integrate the
        physical and operational data into a single source of truth. We can
        build a Digital Twin with 3D accuracy and component level piping
        information or we can take it a step further to include data mining and
        analytics. These analytical models can be used to develop new
        information about the facility such as predictive maintenance schedules
        and operational system simulations. The value of the Digital Twin is
        unique to each client and we create a customized solution to each
        application.
      </p>
      <h2 class="paragraph-header">
        3D Laser Scanning - Reality Capture To Reduce Operational Risk and
        Improve Efficiency
      </h2>
      <p>
        Many facility upgrades and operational tasks can get mired due to safety
        risks, repeat site visits, project delays, cost overruns. Many of these
        issues can be mitigated through the utilization of accurate 3D laser
        scans.
      </p>
      <p>
        A laser scan is a powerful laser recording of field data that captures a
        site's shapes and appearance and converts those conditions into a cloud
        of datapoints which can be visualized, measured, and virtually walked
        thru. Integrated into CAD programs the scan data provides accurate basis
        for the development of 3D BIM as-built models.
      </p>
      <p>
        Tetra Tech Rooney has extensive expertise acquiring, processing,
        modeling and disseminating laser scan and LiDAR data for engineering
        disciplines and lay end users requiring solutions to virtually visualize
        facilities and assets.
      </p>
      <p>
        Our team is led by a group of talented multi-discipline engineers,
        designers, photogrammetrists, and GIS professionals, that have built
        efficient workflows and techniques for utilizing LiDAR and laser scan
        data across multi-discipline engineering design projects for facility
        documentation, planning, accurate and efficient CAD modeling and
        as-built deliverable generation.
      </p>
      <div class="image-container">
        <img
          src="../../../../../assets/img/3d-laser-scan-steps.webp"
          alt="stpes to complete a 3d laser scan"
        />
      </div>
      <div id="bottom-spacer"></div>
    </div>
    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
