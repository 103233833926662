import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about-management',
  templateUrl: './about-management.component.html',
  styleUrls: ['./about-management.component.css'],
})
export class AboutManagementComponent implements OnInit {
  markStart: Date = new Date('1/1/1984');
  troyStart: Date = new Date('1/1/1991');
  troyStart2: Date = new Date('1/1/1995');
  mattStart: Date = new Date('1/1/2012');
  edStart1: Date = new Date('1/1/1988');
  edStart2: Date = new Date('1/1/1997');
  zachStart: Date = new Date('11/1/2005');
  stevenStart: Date = new Date('1/1/2007');
  geneStart: Date = new Date('4/26/2006');
  joeStart: Date = new Date('1/1/2007');
  justinStart: Date = new Date('1/1/2005');
  justinStart2: Date = new Date('1/1/2009');
  joshStart: Date = new Date('1/1/2005');
  reeseStart: Date = new Date('1/1/1991');

  markYears: number;
  troyYears: number;
  troyYears2: number;
  mattYears: number;
  edYears1: number;
  edYears2: number;
  zachYears: number;
  stevenYears: number;
  geneYears: number;
  joeYears: number;
  justinYears: number;
  justinYears2: number;
  joshYears: number;
  reeseYears: number;

  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('Management - Tetra Tech Rooney');
    this.getYears();
  }

  getYears(): void {
    const today = new Date();
    this.markYears = Math.floor(
      (today.valueOf() - this.markStart.valueOf()) / 31536000000
    );
    this.troyYears = Math.floor(
      (today.valueOf() - this.troyStart.valueOf()) / 31536000000
    );
    this.troyYears2 = Math.floor(
      (today.valueOf() - this.troyStart2.valueOf()) / 31536000000
    );
    this.mattYears = Math.floor(
      (today.valueOf() - this.mattStart.valueOf()) / 31536000000
    );
    this.edYears1 = Math.floor(
      (today.valueOf() - this.edStart1.valueOf()) / 31536000000
    );
    this.edYears2 = Math.floor(
      (today.valueOf() - this.edStart2.valueOf()) / 31536000000
    );
    this.zachYears = Math.floor(
      (today.valueOf() - this.zachStart.valueOf()) / 31536000000
    );
    this.stevenYears = Math.floor(
      (today.valueOf() - this.stevenStart.valueOf()) / 31536000000
    );

    this.geneYears = Math.floor(
      (today.valueOf() - this.geneStart.valueOf()) / 31536000000
    );
    this.joeYears = Math.floor(
      (today.valueOf() - this.joeStart.valueOf()) / 31536000000
    );
    this.justinYears = Math.floor(
      (today.valueOf() - this.justinStart.valueOf()) / 31536000000
    );
    this.justinYears2 = Math.floor(
      (today.valueOf() - this.justinStart2.valueOf()) / 31536000000
    );
    this.joshYears = Math.floor(
      (today.valueOf() - this.joshStart.valueOf()) / 31536000000
    );
    this.reeseYears = Math.floor(
      (today.valueOf() - this.reeseStart.valueOf()) / 31536000000
    );
  }
}
