<div class="wrapper">
  <div class="content-containter" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div class="content">
      <div id="header-container">
        <h1 id="page-header" class="topper">Management</h1>
      </div>
      <mat-divider class="name-separation"></mat-divider>

      <div
        class="name-header-block"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutGap="1rem"
      >
        <div class="name-title" fxFlex="1 1 20%">
          <h2 class="name">Troy Pierantoni, PE</h2>
          <h3 class="title">President</h3>
        </div>

        <div class="head-shot" fxFlex="1 1 20%">
          <img
            src="../../../../assets/img/troy-pierantoni.webp"
            alt="head shot of Ed Kelly"
          />
        </div>
      </div>
      <p class="summary">
        Troy brings over {{ troyYears }} years of oil and gas midstream
        engineering experience, both as an engineer for a pipeline operating
        company (4 years), as well as a consultant ({{ troyYears2 }} years).
        Troy has extensive experience with design of pipeline facilities, design
        of pipelines, vast pipeline integrity experience, as well as pipeline
        operational experience. In his career, Troy has filled roles including
        Project Director, Project Manager, Design Manager, and Project Engineer.
        Facility experience includes pump stations, terminals, check metering,
        custody transfer metering, and truck unloading. Related pipeline
        integrity activities include completing system pipeline integrity
        analysis, hydrostatic testing, managing in-line inspection programs,
        managing extensive anomaly repair programs and assisting with the
        development and review of DOT-mandated integrity management programs.
      </p>
      <mat-divider class="name-separation"></mat-divider>

      <div
        class="name-header-block"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutGap="1rem"
      >
        <div class="name-title" fxFlex="1 1 20%">
          <h2 class="name">Mark B. Johnke</h2>
          <h3 class="title">Vice President, Engineering</h3>
        </div>
        <div class="head-shot" fxFlex="1 1 20%">
          <img
            src="../../../../assets/img/mark-johnke.webp"
            alt="head shot of Ed Kelly"
          />
        </div>
      </div>
      <p class="summary">
        Mark Johnke has {{ markYears }} years of experience in the petroleum
        transportation industry and has managed engineering departments and
        complex projects ranging from pipeline and station maintenance to new
        facility construction. <br />
        <br />
        Mark has extensive operations and maintenance project experience,
        including prover water draws and piping retirements to maritime vessel
        dry-docking, station manifold revisions, API 653 inspections, storage
        tank renovations, line lowering and pipeline in-line inspections and
        rehabilitation. In the area of new construction, Mark’s experience
        encompasses installing main line pumps and the associated piping, check
        metering, proving and boosting facilities, natural gas pipeline within
        shared rights-of-way, larger crude oil pipelines, new storage tanks,
        tank inert-gas blanketing, produced water handling and high-pressure
        injection, products loading terminal expansion and construction of
        railcar-supplied truck loading facilities.
      </p>
      <mat-divider class="name-separation"></mat-divider>

      <div
        class="name-header-block"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutGap="1rem"
      >
        <div class="name-title" fxFlex="1 1 20%">
          <h2 class="name">Ed J. Kelly, PE</h2>
          <h3 class="title">Vice President, Principal Project Manager</h3>
        </div>
        <div class="head-shot" fxFlex="1 1 20%">
          <img
            src="../../../../assets/img/ed-kelly.webp"
            alt="head shot of Ed Kelly"
          />
        </div>
      </div>

      <p class="summary">
        Ed has {{ edYears1 }} years of notable experience in Oil and Gas sector
        including {{ edYears2 }}
        years with Tetra Tech Rooney. Ed is currently a licensed professional
        engineer in 30 states. Ed started his career with Marathon Petroleum
        where he functioned as project manager and project engineer for
        pipeline, terminals, and retail facility projects. While at Tetra Tech
        Rooney, Ed has demonstrated his expertise in project management, design,
        and construction management of pipelines, pipeline pump stations and
        terminals. Since 1998, Ed has been extensively involved with pipeline
        integrity projects including a preeminent expertise in the Integrity
        Management Rule. Ed is heavily involved with Tetra Tech Rooney’s
        business development efforts and proposal preparation. Recently Ed has
        been leading Tetra Tech’s renewables efforts with our oil and gas
        customers.
      </p>
      <mat-divider class="name-separation"></mat-divider>

      <div
        class="name-header-block"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutGap="1rem"
      >
        <div class="name-title" fxFlex="1 1 20%">
          <h2 class="name">Josh Whisenhunt, PE</h2>

          <h3 class="title">Principal Project Manager</h3>
        </div>
        <div class="head-shot" fxFlex="1 1 20%">
          <img
            src="../../../../assets/img/josh-whisenhunt.webp"
            alt="head shot of Josh Wisenhunt"
          />
        </div>
      </div>

      <p class="summary">
        Josh is a licensed Professional Engineer with over {{ joshYears }} years
        at TtR. As a Principal Project Manager, Josh has experience managing
        projects and leading interdisciplinary teams for all phases of project
        development, detailed design and field execution/construction. An
        overview of Josh’s oil and gas industry experience includes pipeline
        integrity (in-line inspection, repair/rehabilitation programs and
        geohazard mitigation), directional drills (HDD), cross-country pipeline
        routing and detailed design, and terminal & pump station facility
        design. Josh has an extensive background with Greenfield facilities for
        new capital projects, as well as Brownfield expansion and maintenance
        for existing facilities that must remain in-service during upgrades and
        modifications. Josh has also functioned as an embedded project manager
        for pipeline owner/operators for a wide variety of schedule-driven and
        challenging projects.
      </p>

      <mat-divider class="name-separation"></mat-divider>

      <div
        class="name-header-block"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutGap="1rem"
      >
        <div class="name-title" fxFlex="1 1 20%">
          <h2 class="name">Justin Thomas, PE</h2>
          <h3 class="title">Principal Project Manager</h3>
        </div>
        <div class="head-shot" fxFlex="1 1 20%">
          <img
            src="../../../../assets/img/justin-thomas.webp"
            alt="head shot of Justin Thomas"
          />
        </div>
      </div>

      <p class="summary">
        Justin is a licensed professional engineer with over
        {{ justinYears }} years of midstream oil and gas industry experience.
        Justin’s background includes 4 years of experience with Conoco Phillips
        Pipe Line Company as a district operations and project engineer, and
        over {{ justinYears2 }} years as a consulting engineer. Justin’s project
        experience includes pipeline facility design, custody transfer systems,
        leak detection systems, relief and overpressure protection systems,
        pipeline injection/transfer/booster and mainline pumps, control valves,
        truck offloading and loading facilities, city pipeline design, nitrogen
        purges, complex directional drills, bores, backup generators, trans mix
        injection & sampling systems and pipeline strain gage monitoring
        systems. Justin has experience with project development, budgeting/cost
        estimating, hydraulics, detailed design, process hazard analysis,
        management of change, construction specification development, bidding,
        contractor evaluation, inspector evaluation and selection, project
        management, material procurement, field construction management,
        pressure testing, technical planning and support for tie-in activities,
        project commissioning, DOT records compliance and drawing as-builds. His
        main experience is with liquid pipelines; crude, refined products and
        HVLs.
      </p>

      <mat-divider class="name-separation"></mat-divider>

      <div
        class="name-header-block"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutGap="1rem"
      >
        <div class="name-title" fxFlex="1 1 20%">
          <h2 class="name">Gene Rieker</h2>
          <h3 class="title">Principal Project Manager</h3>
        </div>
        <div class="head-shot" fxFlex="1 1 20%">
          <img
            src="../../../../assets/img/gene-rieker.webp"
            alt="head shot of Gene Rieker"
          />
        </div>
      </div>

      <p class="summary">
        Gene has {{ geneYears }} years of pipeline engineering and project
        management experience, as well as 4 years of civil engineering
        experience. Gene's roles included project management, detailed
        engineering design, cost estimating, procurement, contract development,
        cost control, schedule control, commissioning, construction management,
        concrete testing, nuclear soil density testing, sub-division
        development, phase 1 and 2 environmental assessments, ground water
        monitoring, environmental water and soil sampling, superfund remediation
        sites, soil remediation, SPCC, SWPP plans, geotechnical investigation,
        Civil 3D, and open pit mine permitting. Typical project related duties
        include completion of a project feasibility study, cost estimate,
        detailed engineering and design, completion of construction drawings,
        scope of work development, hydraulic analysis, construction contract
        documents, development of specifications, material specification,
        project scheduling, construction management and administration,
        hydro-test planning and approval, project cost accounting, construction
        documentation, station commissioning and project close out.
        <br />
        <br />
        Noteworthy technical accomplishments include design and construction of
        midstream oil facilities and construction oversight for various projects
        in North Dakota, New Mexico, Texas and Canada. Total value of all
        projects managed exceeds $550MM and staffing levels of up to 20
        personnel. He has completed all projects within the in-service date,
        below budget and with no lost-time incidents.
      </p>
      <mat-divider class="name-separation"></mat-divider>

      <div
        class="name-header-block"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutGap="1rem"
      >
        <div class="name-title" fxFlex="1 1 20%">
          <h2 class="name">Zachary Bauer, PE</h2>
          <h3 class="title">Principal Project Manager</h3>
        </div>
        <div class="head-shot" fxFlex="1 1 20%">
          <img
            src="../../../../assets/img/zachary-bauer.webp"
            alt="head shot of Zachary Bauer"
          />
        </div>
      </div>

      <p class="summary">
        Zachary is a licensed professional engineer with over
        {{ zachYears }} years of midstream oil and gas industry experience. As a
        Principal Project Manager, he is responsible for leading a
        multidisciplinary team of engineers and designers on a variety of
        midstream projects, including pipelines, pump stations, meter stations,
        and storage facilities. Zachary has successfully managed the engineering
        of major capital projects with total installed costs over $1B. Through
        project work, Zachary has hands on experience with pipeline route
        selection, alignment sheets, HDD and bore crossing design, hydrostatic
        test calculations and planning, and pipeline hot taps. Zachary has a
        proven track record of successfully completing large pipeline projects,
        including those with lengths over 350 miles, mainline pumps up to 4500
        HP, and pipe diameters up to 36”. These projects included pipelines
        routed through urban and industrial areas, including crossings of
        interstates, railroads, major waterbodies, high voltage transmission
        lines, and other foreign utilities.
      </p>

      <mat-divider class="name-separation"></mat-divider>

      <div
        class="name-header-block"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutGap="1rem"
      >
        <div class="name-title" fxFlex="1 1 20%">
          <h2 class="name">Steven Ricci</h2>
          <h3 class="title">Principal, Electrical Engineering Manager</h3>
        </div>
        <div class="head-shot" fxFlex="1 1 20%">
          <img
            src="../../../../assets/img/steven-ricci.webp"
            alt="head shot of Steven Ricci"
          />
        </div>
      </div>

      <p class="summary">
        Steve has {{ stevenYears }} years of experience in the petroleum
        transportation industry that covers all aspects of electrical,
        instrumentation, and controls. He has extensive field experience
        including instrument configuration, trouble shooting, relay programming,
        start-up support, and commissioning coordination/management. He is well
        versed in all locations from pipeline right of way, to truck facilities,
        pump stations, and control rooms.<br />
        <br />
        Over his time at Tetra Tech Rooney, he has worked for many clients, from
        small start-up companies, to large international companies. His main
        goal has been to continually streamline and improve the product that
        Tetra Tech Rooney produces, so the result is more useful from project
        construction through facility maintenance. The focus is to make the
        design packages adaptable to each of our clients’ needs, so it matches
        the product they expect.
      </p>
      <mat-divider class="name-separation"></mat-divider>

      <div
        class="name-header-block"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutGap="1rem"
      >
        <div class="name-title" fxFlex="1 1 20%">
          <h2 class="name">Reese Lundgren, PE</h2>
          <h3 class="title">Principal Engineering Manager</h3>
        </div>
        <div class="head-shot" fxFlex="1 1 20%">
          <img
            src="../../../../assets/img/reese-lundgren.webp"
            alt="head shot of Zachary Bauer"
          />
        </div>
      </div>

      <p class="summary">
        Reese is a licensed Professional Engineer with over
        {{ reeseYears }} years of civil engineering and structural experience in
        the fields of water treatment and distribution, land development and oil
        industry support. His expertise includes project management, design,
        construction management and operations of water treatment, distribution
        and storage facilities; large land development projects; and design and
        construction management for the civil and structural aspects of liquid
        pipeline, pump station, and product distribution terminal projects. As
        Principal Engineering Manager with Tetra Tech (TtR), Reese is the
        Discipline Lead for the Civil and Structural design teams, and oversees
        the CADD Group.
      </p>

      <mat-divider class="name-separation"></mat-divider>

      <div
        class="name-header-block"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutGap="1rem"
      >
        <div class="name-title" fxFlex="1 1 20%">
          <h2 class="name">Joe Meier</h2>

          <h3 class="title">Business Development Director</h3>
        </div>
        <div class="head-shot" fxFlex="1 1 20%">
          <img
            src="../../../../assets/img/joe-meir.webp"
            alt="head shot of Joe Meier"
          />
        </div>
      </div>

      <p class="summary">
        Joe Meier is the Business Development Director for Tetra Tech Rooney,
        responsible for obtaining new work, coordinating services with other
        Tetra Tech disciplines, and maintaining client relationships across our
        diverse project spectrum. Joe began his career in the upstream service
        industry working for Halliburton as a drilling fluids engineer. Over
        {{ joeYears }}
        years, he held various business development, engineering, and management
        roles across locations in the United States, Southeast Asia, and
        Australia. A graduate of Montana State University, Joe earned a Bachelor
        of Science in mechanical engineering. Joe is excited to be back in his
        home state of Montana and supporting energy development projects across
        the country.
      </p>

      <mat-divider class="name-separation"></mat-divider>

      <div
        class="name-header-block"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutGap="1rem"
      >
        <div class="name-title" fxFlex="1 1 20%">
          <h2 class="name">Matt Brown, PE</h2>
          <h3 class="title">Principal Engineering Manager</h3>
        </div>
        <div class="head-shot" fxFlex="1 1 20%">
          <img
            src="../../../../assets/img/matt-brown.webp"
            alt="head shot of Zachary Bauer"
          />
        </div>
      </div>

      <p class="summary">
        Matthew Brown is a licensed professional engineer (PE) and certified
        project management professional (PMP) with {{ mattYears }} years of
        midstream oil and gas experience. As a Project Manager, Matthew has
        managed more than $15M in internal consulting budget (up to $3.9M max
        annual project budget) working for 20 different midstream oil and gas
        operators with an emphasis in Asset Integrity (Pipeline and Facility).
        Project types include Inline inspection (ILI), NDT, cathodic protection,
        pressure testing, ECDA, ICDA, SCCDA, Risk assessment, compliance and
        due-diligence auditing, leak detection, and capital pipeline projects.
      </p>

      <div id="bottom-spacer"></div>
    </div>

    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
