<div class="wrapper">
  <div class="header-image"></div>

  <div class="content-container" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div
      class="content-block"
      fxLayout="column"
      fxFlex="1 1 60%"
      fxFlex.lt-md="1 1 90%"
    >
      <div class="text-header">
        <h1 id="page-header">Projects</h1>
        <p>
          Since our inception in the early eighties, TtR has completed over
          2,500 projects with wide ranging complexity. Our oil and gas
          transportation focus keeps us well informed with the latest
          regulations and creates an environment where our engineers and
          designers are extremely proficient and effective. In addition to our
          vast engineering design experience, many of our personnel also have
          experience working directly for operating companies. This insight
          shapes our practical execution from front-end engineering and design
          through procurement, commissioning, and startup. We tailor our
          deliverables, engineering rigor, and project team approach to the
          client’s needs. This sensitivity allows TtR to deliver cost-effective
          and rigorous designs for both small and large projects.
        </p>
      </div>
      <div
        class="picture-grid-container"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="space-between"
        fxLayoutGap.lt-md="1rem"
      >
        <div class="link-card" fxFlex.gt-md="1 1 45%">
          <a fxLayout="column" routerLink="/projects/facilities">
            <div class="card-title" fxFlex="1 1 10%">
              <h2 class="paragraph-header">Facilities</h2>
            </div>

            <div class="image-container" id="image1" fxFlex="1 1 100%"></div>
          </a>
        </div>
        <div class="link-card" fxFlex.gt-md="1 1 45%">
          <a fxLayout="column" routerLink="/projects/gathering">
            <div class="card-title" fxFlex="1 1 10%">
              <h2 class="paragraph-header">Gathering Systems</h2>
            </div>

            <div class="image-container" id="image2" fxFlex="1 1 100%"></div>
          </a>
        </div>
      </div>
      <div class="spacer"></div>
      <div
        class="picture-grid-container"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="space-between"
        fxLayoutGap.lt-md="1rem"
      >
        <div class="link-card" fxFlex.gt-md="1 1 45%">
          <a fxLayout="column" routerLink="/projects/pipeline">
            <div class="card-title" fxFlex="1 1 10%">
              <h2 class="paragraph-header">Pipelines</h2>
            </div>

            <div class="image-container" id="image3" fxFlex="1 1 100%"></div>
          </a>
        </div>
        <div class="link-card" fxFlex.gt-md="1 1 45%">
          <a fxLayout="column" routerLink="/projects/integrity">
            <div class="card-title" fxFlex="1 1 10%">
              <h2 class="paragraph-header">Integrity</h2>
            </div>

            <div class="image-container" id="image4" fxFlex="1 1 100%"></div>
          </a>
        </div>
      </div>
      <div class="spacer"></div>
    </div>

    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
