<div class="wrapper">
  <div class="content-containter" fxLayout="row" fxFlex="1 1 100%">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div class="content">
      <h1 id="page-header">Integrity and Rehabilitation</h1>
      <div class="image-container" id="image-1"></div>
      <h2 class="paragraph-header">
        Holistic services to improve asset integrity across the lifecycle
      </h2>

      <p>
        Many of the vital pipeline transmission systems constructed fifty years
        ago continue to be in operation today. As these underground utilities
        age, it is critical to understand their condition to reduce safety and
        environmental risks.
      </p>
      <p>
        We help our clients evaluate the economic efficiency and physical
        condition of aged pipelines to determine whether rehabilitation is
        necessary. We work to understand our client’s priorities and aid in
        establishing the most cost-effective and operations-sensitive in-line
        inspection and geohazard programs, customized for each application.
      </p>
      <ul>
        <li>Integrity management plans</li>
        <li>Consequence Analysis: HCA/ MCA identification and monitoring</li>
        <li>Pipeline assessment program</li>
        <li>Geohazard identification and mitigation</li>
        <li>Integrity program management</li>
        <li>Environmental permitting and clearances</li>
        <li>Pipeline repair and project management</li>
        <li>Data management and regulatory compliance</li>
      </ul>
      <p>
        TtR has completed in-line inspection assessments and rehabilitation on
        over 4,500 miles of oil and gas pipelines.
      </p>
    </div>
    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
