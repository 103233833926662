import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-project-management-scheduling',
  templateUrl: './project-management-scheduling.component.html',
  styleUrls: ['./project-management-scheduling.component.css'],
})
export class ProjectManagementSchedulingComponent implements OnInit {
  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('Scheduling - Tetra Tech Rooney');
  }
}
