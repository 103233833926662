import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-projects-facilities',
  templateUrl: './projects-facilities.component.html',
  styleUrls: ['./projects-facilities.component.css'],
})
export class ProjectsFacilitiesComponent implements OnInit {
  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('Facilities Projects - Tetra Tech Rooney');
  }
}
