<div class="wrapper">
  <div class="content-containter" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div class="content" fxLayout="column">
      <h1 id="page-header">Trenchless Crossings</h1>

      <div class="image-container"></div>
      <h2 class="paragraph-header">
        What is Horizontal Directional Drilling (HDD)?:
      </h2>
      <p>
        Horizontal Directional Drilling (HDD) is a trenchless construction
        method used to install utilities underneath sensitive areas and existing
        infrastructure without disturbing the above ground surface. The HDD
        process consists of drilling a small diameter pilot hole along a
        designed path (Step 1 - Pilot), reaming the hole to a larger diameter
        suitable for the product pipe to be installed in (Step 2 - Ream), and
        pulling back the pre-welded pipe string through the reamed hole to
        complete the HDD installation (Step 3 - Pullback).
      </p>
      <h2 class="paragraph-header">What is Direct Pipe?:</h2>
      <p>
        Direct Pipe is a new and emerging trenchless construction method that
        combines the advantages of micro tunneling and HDD technology. It is an
        alternative to HDD and currently can be used to install large diameter
        pipelines ranging in size from 30”OD – 60”OD. Direct Pipe differs from
        HDD in that it is a one step process, where the borehole is excavated
        via a tunneling machine and the prefabricated pipeline is installed in
        one single continuous step, via a pipe thruster, as the tunneling
        machine progresses along the designed path.
      </p>
      <h2 class="paragraph-header">Tetra Tech Rooney Capabilities:</h2>
      <p>
        Tetra Tech Rooney has trained engineers with Professional Licenses in
        nearly all 50 states and has an extensive background applying HDD and
        Direct Pipe to unconventional crossing of rivers, lakes, wetlands,
        railroads, highways, and other challenging obstacles. Tetra Tech
        Rooney’s technical expertise includes detailed design of simple
        (vertical curve only) and complex (vertical + horizontal or combined
        curves) plan and profiles, pipe stress analysis and calculations,
        as-built radius review calculations and installation analysis.
      </p>

      <h2 class="paragraph-header">
        Typical Design and Construction Activities include the following:
      </h2>
      <div class="text-container">
        <ul>
          <li>Site visit / field reconnaissance and aerial imagery review</li>
          <li>Facilitate Geotechnical sampling, analysis, and reporting</li>
          <li>
            Facilitate discipline surveys (Environmental, Civil, and
            Topographic)
          </li>
          <li>Risk assessment and mitigation alternatives</li>
          <li>
            Easement layout and sizing of workspace for entry, exit and pullback
            staging areas
          </li>
          <li>
            Pipe Stress Analysis (operational stress and installation/pullback
            stress calculations)
          </li>
          <li>Facilitate annular pressure and hydraulic fracture analysis</li>
          <li>
            Develop preliminary and final construction drawings and
            specifications
          </li>
          <li>
            Develop permit drawings (road crossings, railroads, foreign
            utilities, etc.)
          </li>
          <li>Environmental and non-environmental permitting</li>
          <li>Construction support and HDD progress monitoring</li>
          <li>As-built radius review calculations and installation analysis</li>
          <li>Develop hydrostatic test plans</li>
          <li>Hydrostatic test monitoring</li>
          <li>Provide As-Built drawings and facilitate permit closeouts</li>
        </ul>
      </div>

      <p>
        From beginning to end Tetra Tech Rooney has the technical expertise to
        make your trenchless construction project successful.
      </p>
    </div>
    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
