<div class="wrapper">
  <div class="content-containter" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div class="content">
      <h1 id="page-header">Electrical Engineering</h1>
      <p>
        TtR staff has experience in completing designs including power
        distribution, overcurrent protection, electrical equipment buildings,
        lighting, heat trace, PLCs, cable & conduit routing with trenching,
        ductbanks, & cable tray, and hazardous area classification
        considerations. Typical equipment designs include HV-MV transformers,
        MV-LV transformers (both dry-type and liquid filled), VFDs, soft starts,
        5KV switchgear/MCCs, 480V MCCs, low voltage panels, and power
        distribution centers (PDC/MCC/ESB).
      </p>

      <p>
        We are also experts at
        <a class="typical-link" routerLink="/solutions/engineering/arc-flash"
          >power system modelling and arc flash evaluations.</a
        >
      </p>
      <p>
        TtR is also very familiar with all aspects of cathodic protection (CP)
        design for pipelines and related facilities, including AC/DC
        mitigations.
      </p>
      <p>
        For instrumentation and controls, our design abilities span from
        creating/optimizing P&IDs, specifying instruments, instrument databases,
        field start-up of complicated instruments (such as coriolis meters, tank
        radar, etc.), creating communication schematics and control
        infrastructure (including detailed systems such as DeviceNet,
        ControlNet, Ethernet ring topology), creating detailed loop drawings,
        designing PLCs, creating programming documentation (such as alarm
        matrices, shutdown keys, sequence of operation documents), and
        experience in PLC programming (ladder logic).
      </p>
      <div class="image-container"></div>
      <div class="spacer"></div>
    </div>
    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
