import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-tech-automation',
  templateUrl: './tech-automation.component.html',
  styleUrls: ['./tech-automation.component.css'],
})
export class TechAutomationComponent implements OnInit {
  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('Automation and Programing - Tetra Tech Rooney');
  }
}
