<div class="wrapper">
  <div class="content-containter" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div class="content">
      <h1 id="page-header">Facility Engineering</h1>
      <p>
        Industry experience, diligence, and creativity are required to prepare a
        design that ensures the safety and operational success of a facility.
        Our team works closely with owners and developers to meet performance
        goals and cost objectives.
      </p>
      <p>
        Our design expertise includes the development of full engineering
        packages including piping and instrumentation diagrams (P&ID), process
        flow diagrams (PFD), construction and demolition piping plan and section
        drawings, typical details, fabrication drawings, and isometric
        construction drawings.
      </p>
      <p>
        Our
        <a class="typical-link" routerLink="/projects/facilities"
          >Facilities Projects Page</a
        >
        contains a few examples of some of our work.
      </p>
      <div class="image-container">
        <img src="../../../../../assets/img/facility-engineering.webp" />
      </div>
      <div class="text-container"></div>
    </div>
    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
