import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-engineering-civil-struct',
  templateUrl: './engineering-civil-struct.component.html',
  styleUrls: ['./engineering-civil-struct.component.css'],
})
export class EngineeringCivilStructComponent implements OnInit {
  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle(
      'Civil / Structural Engineering - Tetra Tech Rooney'
    );
  }
}
