<div class="wrapper">
  <div class="content-containter" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div class="content" fxLayout="column">
      <h1 id="page-header">Arc Flash and Power System Modeling</h1>

      <div class="text-container" id="top-block" fxFlex="1 1 60%">
        <p class="content-text">
          As commercial operations grow more reliant upon electrical equipment,
          the advantages provided by a power system analysis are more valuable
          than ever. Arc flash studies and power system analyses offer an
          expansive set of tools to ensure your operation is NFPA 70E Article
          110 compliant, as well as the ability to study your operation costs,
          increase equipment life, and provide a safer environment for
          technicians.
        </p>
        <p class="content-text">
          In recent years, Tetra Tech Rooney (TtR) has completed arc flash
          studies and power system analyses for over 200 sites of varying size
          and complexity. Following our proven and efficient process, TtR
          continually exceeds our clients’ expectations for budget, schedule,
          and quality.
        </p>
      </div>

      <div class="list-image-container" fxLayout="row" fxLayout.lt-md="column">
        <div class="image-container" fxFlex.gt-md="1 1 75%">
          <img src="../../../../../assets/img/arc-flash1.webp" />
        </div>
        <div class="list-container" fxFlex.gt-md="1 1 25%">
          <h3 class="list-header">Scalable Engineering and Design Services</h3>
          <ul>
            <li>Site visit/walkdowns</li>
            <li>Inspect electrical equipment for safety issues</li>
            <li>One-line markups and as-builts</li>
            <li>Utility coordination</li>
            <li>Data gathering from equipment vendors</li>
            <li>
              Customized model designed around the clients’ needs and standards
              using the latest versions of SKM or ETAP.
            </li>
            <li>
              UV/outdoor rated arc flash warning labels including a label
              placement guide
            </li>
            <li>
              PE stamped Power System Analysis report tailored to the clients’
              needs
            </li>
            <li>Final deliverables package transmitted electronically</li>
          </ul>
        </div>
      </div>

      <div class="list-image-container" fxLayout="row" fxLayout.lt-md="column">
        <div class="list-container" fxFlex.gt-md="1 1 50%">
          <h3 class="list-header">Available Power System Analyses</h3>
          <ul>
            <li>Arc Flash</li>
            <li>Load Flow</li>
            <li>Protection & Coordination</li>
            <li>Motor Acceleration</li>
            <li>Harmonics</li>
            <li>DC Arc Flash</li>
            <li>DC Load Flow</li>
            <li>DC Short Circuit</li>
            <li>Grounding Grid Systems</li>
            <li>Power Usage Cost Analysis</li>
          </ul>
          <h3 class="list-header">Applicable Industries</h3>
          <ul>
            <li>Oil & Gas</li>
            <li>Mining</li>
            <li>Forestry & Lumber</li>
            <li>Wastewater Treatment</li>
            <li>Manufacturing Facilities</li>
            <li>Refineries</li>
            <li>Chemical & Pharmaceutical Plants</li>
            <li>Utility Sites</li>
            <li>Commercial</li>
          </ul>
        </div>
        <div class="image-container" fxFlex.gt-md="1 1 50%">
          <img src="../../../../../assets/img/arc-flash3.webp" />
        </div>
      </div>
    </div>
    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
