import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-projects-gathering',
  templateUrl: './projects-gathering.component.html',
  styleUrls: ['./projects-gathering.component.css'],
})
export class ProjectsGatheringComponent implements OnInit {
  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle(
      'Gathering Systems Projects - Tetra Tech Rooney'
    );
  }
}
