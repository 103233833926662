<div class="wrapper">
  <div class="content-containter" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>

    <div class="content" fxFlex>
      <h1 id="page-header">Procurement and Supply Chain</h1>
      <div class="image-container" id="image-1"></div>
      <div class="small-spacer"></div>
      <p>
        Tetra Tech Rooney (TtR) takes an active approach to all steps in the
        procurement process. We’ve developed proprietary tools to assist in
        automating many of the typical tasks required, making our procurement
        efforts as efficient as possible. We work with our clients to prioritize
        material and equipment procurement based on project schedule and
        production lead times. We become fully versed in our clients’
        specifications and the project requirements in order to prepare
        accurate, detailed, and complete Request for Quotation (RFQ) packages.
        This minimizes requests for additional information, results in vendor
        bids that meet all criteria, and makes the quotation process more
        efficient. We work with our clients to identify qualified bidders,
        delivery logistics, and field service requirements. Bids are normalized
        and evaluated technically and commercially. TtR then provides a thorough
        and detailed recommendation. We assist our clients with preparing and
        issuing Requests for Purchase (RFP). Post purchase order, TtR remains
        involved as we believe managing production tasks is essential to
        ensuring on time material and equipment deliveries. TtR will manage
        factory inspections, required vendor documentation, production meetings,
        change orders; communicate with suppliers and manufacturers; track
        milestones; and maintain overall schedule and delivery. Staying involved
        throughout the production process allows us to quickly address potential
        issues that arise to get back on track. TtR understands all aspects of
        the procurement process and that expertise is one key to our clients’
        overall project success.
      </p>
      <p>
        Tetra Tech has extensive experience with the field coordination of
        material receipt and distribution. We identify the requirements,
        evaluate the logistics involved, and develop and implement the
        appropriate procedures. This applies to aspects such as sizing and
        locating material yards to optimize constructability, prioritizing
        distribution to field crews, organizing and maintaining material
        certifications and field documentation, and tracking inventories.
      </p>
    </div>
    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
