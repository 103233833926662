<div class="wrapper">
  <div class="content-containter" fxLayout="row">
    <div class="left-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
    <div class="content">
      <h1 id="page-header">WebApp GIS Digital Dashboard</h1>

      <div id="text-image-box1">
        <p>
          Project data can be quite complex, dynamic, and difficult to
          communicate. Relevant parties need to have real-time access to the
          correct data set to accurately perform their work activities. Also, it
          is critical to have visibility of the project metrics and progress so
          that necessary actions can be taken in a timely manner to retain
          schedule and budget.
        </p>
        <p>
          Tetra Tech Rooney (TtR) has developed customized WebApp platforms to
          help solve these communication challenges. WebApp is an internet-based
          map viewer, document interface, and project dashboard tool that
          utilizes an integrated GIS database to efficiently communicate all
          project related data and improve decision-making capabilities within a
          centralized user-friendly platform.
        </p>
      </div>

      <div id="text-image-box2" fxLayout="row" fxLayout.lt-md="column">
        <div class="text-container" fxFlex.gt-md="1 1 25%">
          <h2 class="paragraph-header">WebApp Features</h2>
          <ul>
            <li>Access to project documents and drawings</li>
            <li>Merging of project layers, data, and linework</li>
            <li>Improved inter-discipline communication</li>
            <li>Management, environmental, operations, land, contractors</li>
            <li>Project management, cost controls, and dashboard</li>
            <li>Asset management</li>
          </ul>
        </div>

        <div class="image-container" fxFlex.gt-md="1 1 75%">
          <img
            src="../../../../../assets/img/web-map-dashboard.webp"
            alt="webapp dashboard view"
          />
        </div>
      </div>

      <div id="text-image-box3" fxLayout="row" fxLayout.lt-md="column">
        <div class="image-container" fxFlex.gt-md="1 1 75%">
          <img
            src="../../../../../assets/img/web-map-sat-view.webp"
            alt="satelite view of webapp"
          />
        </div>

        <div class="text-container" fxFlex.gt-md="1 1 25%">
          <h2 class="paragraph-header">Diverse Project Applications</h2>
          <ul>
            <li>Route selection</li>
            <li>Land acquisition</li>
            <li>Construction management</li>
            <li>Geohazard assessment tracking</li>
            <li>Asset management</li>
            <li>Anomaly tracking</li>
            <li>Environmental data</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="right-buffer" fxFlex="1 1 20%" fxFlex.lt-md="1 1 5%"></div>
  </div>
</div>
