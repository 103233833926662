<div class="wrapper">
  <div
    class="splash-image-container"
    fxLayout="column"
    fxLayoutAlign="start center"
  >
    <div id="splash-text-spacer" fxFlex="20%" fxFlex.lt-md="10%"></div>
    <div class="fade-in" fxFlex="50%" [ngStyle.lt-md]="{ 'font-size.pt': 25 }">
      ENGINEERING FOR THE ENERGY INDUSTRY
    </div>
  </div>
  <div class="content" fxLayout="row">
    <div fxFlex="1 2 10%" fxFlex.lt-md="5%"></div>
    <div fxFlex="1 1 80%" fxFlex.lt-md="90%">
      <div class="spacer"></div>
      <mat-divider></mat-divider>
      <div id="picture-grid-header-container">
        <h1 id="picture-grid-header" [ngStyle.lt-sm]="{ 'font-size.pt': 35 }">
          <a class="typical-link" routerLink="solutions/solutions-home"
            >SOLUTIONS</a
          >
        </h1>
      </div>
      <mat-divider></mat-divider>
      <div class="spacer"></div>

      <div
        class="picture-grid-container"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="space-between"
        [ngStyle.lt-md]="{ 'height.px': 700 }"
      >
        <div class="image-container" id="image1" fxFlex="1 1 30%">
          <a class="image-link" routerLink="solutions/engineering/facility">
            <span class="image-title">FACILITIES</span>
          </a>
        </div>
        <div class="image-container" id="image2" fxFlex="1 1 30%">
          <a class="image-link" routerLink="solutions/engineering/pipeline">
            <span class="image-title">PIPELINE</span>
          </a>
        </div>
        <div class="image-container" id="image3" fxFlex="1 1 30%">
          <a class="image-link" routerLink="solutions/engineering/electrical">
            <span class="image-title">ELECTRICAL</span>
          </a>
        </div>
      </div>
      <div class="spacer"></div>
      <mat-divider></mat-divider>
      <div id="picture-grid-header-container">
        <h1 id="picture-grid-header" [ngStyle.lt-sm]="{ 'font-size.pt': 35 }">
          <a routerLink="/projects/home" class="typical-link">PROJECTS</a>
        </h1>
      </div>
      <mat-divider></mat-divider>
      <div class="spacer"></div>
      <div
        class="picture-grid-container"
        fxLayout="row"
        fxLayoutAlign="space-between"
        [ngStyle.lt-md]="{ 'height.px': 700 }"
        fxLayout.lt-md="column"
      >
        <div class="image-container" id="image4" fxFlex="1 1 30%">
          <a class="image-link" routerLink="projects/facilities">
            <span class="image-title">STATIONS</span>
          </a>
        </div>
        <div class="image-container" id="image5" fxFlex="1 1 30%">
          <a class="image-link" routerLink="projects/pipeline">
            <span class="image-title-white">PIPELINES</span>
          </a>
        </div>
        <div class="image-container" id="image6" fxFlex="1 1 30%">
          <a class="image-link" routerLink="projects/gathering">
            <span class="image-title">GATHERING SYSTEMS</span>
          </a>
        </div>
      </div>

      <div class="spacer"></div>
      <mat-divider></mat-divider>
      <div class="spacer"></div>
      <div
        class="button-container"
        fxLayout="row"
        fxLayoutAlign="space-around"
        fxLayout.lt-md="column"
        [ngStyle.lt-md]="{ 'height.px': 250 }"
      >
        <button
          mat-raised-button
          class="button"
          routerLink="/contact-us"
          fxFlex="1 1 25%"
        >
          CONTACT US
        </button>
        <a
          href="https://tetratech.referrals.selectminds.com/"
          target="blank"
          mat-raised-button
          class="button"
          fxFlex="1 1 25%"
          fxLayoutAlign="center center"
        >
          CAREERS
        </a>
      </div>
      <div class="spacer"></div>
    </div>
    <div fxFlex="1 2 10%" fxFlex.lt-md="5%"></div>
  </div>
</div>
